import {gql} from 'graphql-request';
import {saasUserFragment} from 'src/services/shared/Fragments';
import {
  nullCollapsableConnectionId,
  nullCollapsableSaasIdentifier,
  nullCollapsableUserPubId,
} from 'src/services/shared/nullCollapsableUtilities';
import {transformMailForward} from 'src/services/shared/sharedTransforms';
import {sortArrayByDateProperty} from 'src/utils/dates';
import {doAuthenticatedQuery} from "src/services/shared/doQuery";

export const forwardsQuery = gql`
  query ExternalForwards (
    $external: Boolean!
    $saasIdentifierBoolExp: saas_bool_exp,
    $userPubIdBoolExp: generic_user_bool_exp,
    $connectionIdBoolExp: uuid_comparison_exp,
  ) {
    mail_forward_view(
      where: {
        mail_forward_recipients: {external: {_eq: $external}},
        _and: [
          {latest_saas_user: {saas: $saasIdentifierBoolExp}},
          {latest_saas_user: {generic_user: $userPubIdBoolExp}},
          {latest_saas_user: {
            connection: {pub_id: $connectionIdBoolExp}
          }},
        ]
      },
    ) {
      latest_saas_user{
        ...User
      }
      primary_email
      mail_forward_recipients(where: {external: {_eq: $external}}) {
        email
        first_detected
      }
    }
  }
  ${saasUserFragment}
`;

export const getMailForwards = async (
  accessToken: string|undefined,
  parameters: any,
  signal?: any, // the prefetch doesn't pass this
) => {
  const {
    isExternal = true,
    saasIdentifier,
    connectionId,
    userPubId,
  } = parameters;

  const userPubIdBoolExp = nullCollapsableUserPubId(userPubId);
  const saasIdentifierBoolExp = nullCollapsableSaasIdentifier(saasIdentifier);
  const connectionIdBoolExp = nullCollapsableConnectionId(
    connectionId
  );

  const variables = {
    external: isExternal,
    saasIdentifierBoolExp,
    userPubIdBoolExp,
    connectionIdBoolExp
  }

  console.log('getMailForwards', {variables})

  const externalForwardQueryFunction = async (client: any) => {
    return await client.request(
      forwardsQuery,
      variables,
      signal,
    );
  };

  let response;
  try {
    response = await doAuthenticatedQuery(externalForwardQueryFunction, accessToken);
    // console.log({response});
  }
  catch (error) {
    console.log('getMailForwards error', {error})
    throw (error)
  }

  response = response.mail_forward_view;

  // ungroup user and transform field names to camel case
  const result = response.map((aForward: any) => {
    aForward = transformMailForward(aForward)
    return aForward;
  });

  const sortedResult = sortArrayByDateProperty(result);
  // console.log({sortedResult});

  return sortedResult;
};


