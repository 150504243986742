import {doAuthenticatedQuery} from "src/services/shared/doQuery";
import {portalUserRoles} from "../../utils/constants";
import {createClientMutation, deleteClientMutation, getAllClientsQuery, updateClientMutation} from "./ClientsGraphQL";
import {ICompany} from "../../types/Company";
import {graphQLClient} from "../shared/GraphQLService";

export const getAllClients = async (
  accessToken: any,
  parameters: any,
) : Promise<ICompany[]> => {
  const {
    role
  } = parameters
  const theQuery = async (client: any) => {
    // Get the list of companies that the user has access to
    // We only need to do this if it is an admin, as the other roles should use default Hasura permissions
    if (role === portalUserRoles.admin) {
      client.setHeader("x-hasura-role", role);
    }

    const result = client.request(
      getAllClientsQuery,
      {
      }
    );
    return result;
  }
  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery, accessToken
    );
  }
  catch (error) {
    console.error('getCompanies failed')
    throw (error)
  }

  return response.client.map((c:any) => {
    return {
      id: c.pub_id,
      name: c.name,
      slug: c.slug,
      planType: c.plan_type,
      disabled: c.is_disabled,
    }
  });
}

export const createClient = async (
  parameters: any,
) => {
  const {
    auth0HasuraAccessToken,
    client_id,
    client,
    role
  } = parameters;

  if (!client) throw new Error('client required');

  const gqlClient = await graphQLClient({accessToken: auth0HasuraAccessToken});

  if (role === portalUserRoles.admin) {
    gqlClient.setHeader("x-hasura-role", role);
  }

  const variables = {
    client_id: client_id,
    client: {
      name: client.name,
      plan_type: client.planType,
    }
  };

  const result = await gqlClient?.request(
    createClientMutation,
    variables
  )
  return result;
}

export const updateClient = async (
  parameters: any,
) => {
  const {
    auth0HasuraAccessToken,
    client_id,
    client,
    role
  } = parameters;

  if (!client) throw new Error('client required');

  const gqlClient = await graphQLClient({accessToken: auth0HasuraAccessToken});

  if (role === portalUserRoles.admin) {
    gqlClient.setHeader("x-hasura-role", role);
  }

  const variables = {
    client_id: client_id,
    client: {
      id: client.id,
      is_disabled: client.disabled,
      plan_type: client.planType,
    }
  };

  const result = await gqlClient?.request(
    updateClientMutation,
    variables
  )
  return result;
}

export const deleteClient = async (
  parameters: any,
) => {
  const {
    auth0HasuraAccessToken,
    client_id,
    client,
    role
  } = parameters;

  if (!client) throw new Error('client required');

  const gqlClient = await graphQLClient({accessToken: auth0HasuraAccessToken});

  if (role === portalUserRoles.admin) {
    gqlClient.setHeader("x-hasura-role", role);
  }

  const variables = {
    client_id: client_id,
    req: {
      client_id: client.id
    }
  };

  const result = await gqlClient?.request(
    deleteClientMutation,
    variables
  )
  return result;
}