// Used to populate user grids when features are disabled
// The grid has a blur so this data is un-readable
// The data is still available in dev tools so don't use real people
export const dummyUserData = [
    {
        "id": "00000000-0000-0000-0000-000000000001",
        "name": "Jason Shaw Jr. Sr. I II III IV V MD DDS PhD DVM",
        "email": "jason.shaw.jr..sr..i.ii.iii.iv.v.md.dds.phd.dvm@kwimbee.org",
        "privilegeLevel": "Highly Privileged User",
        "domain": "Internal",
        "status": "Enabled",
    },
    {
        "id": "00000000-0000-0000-0000-000000000002",
        "name": "Jason Shaw Jr. Sr. I II III IV V MD DDS PhD DVM",
        "email": "jason.shaw.jr..sr..i.ii.iii.iv.v.md.dds.phd.dvm@kwimbee.org",
        "privilegeLevel": "Highly Privileged User",
        "domain": "Internal",
        "status": "Enabled",
    },
    {
        "id": "00000000-0000-0000-0000-000000000003",
        "name": "Katherine Myers",
        "email": "katherine.myers@dynava.org",
        "privilegeLevel": "User",
        "domain": "External",
        "status": "Enabled",
    },
    {
        "id": "00000000-0000-0000-0000-000000000004",
        "name": "Eugene Walker",
        "email": "eugene.walker@browsebug.info",
        "privilegeLevel": "User",
        "domain": "External",
        "status": "Enabled",
    },
    {
        "id": "00000000-0000-0000-0000-000000000005",
        "name": "Jason Shaw Jr. Sr. I II III IV V MD DDS PhD DVM",
        "email": "jason.shaw.jr..sr..i.ii.iii.iv.v.md.dds.phd.dvm@kwimbee.org",
        "privilegeLevel": "Highly Privileged User",
        "domain": "Internal",
        "status": "Enabled",
    }
];
