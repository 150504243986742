import {FC} from 'react';
import {useNavigate} from 'react-router';
import {ErrorScreen} from 'src/components/common/ErrorScreen';
import {userRouteBase} from 'src/routes';
import {cacheKeys} from 'src/services/shared/serviceConstants';
import {getAllGenericUsersFromGraphQL} from 'src/services/UserService/UserService';
import {pause} from 'src/utils/other';
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import {CircularProgress} from '@mui/material';
import {useLegacyQueryWithToken} from "src/hooks/TanstackHooks";

type props = {
  sx?: any,
  props?: props
  // onSelect: any,
  // users: any[],
  // isLoading: boolean,
}

export const UserSearch: FC <props> = ({sx, ...props}) => {

  const navigate = useNavigate();

  const {
    data: allUsers,
    // isLoading: isAllUsersLoading,
    error: allUsersError
  } = useLegacyQueryWithToken(
    [cacheKeys.genericUsersOmitNullNames],
    getAllGenericUsersFromGraphQL,
    {shouldOmitNullNames: true}, // omit null user names
  );

  if (allUsersError) {
    console.error(allUsersError)
    return <ErrorScreen
      message={'problem getting user list'}
    />;
  }

  const handleUserSearch = (
    event: any,
    selectedUser: any
  ): void => {
    if(selectedUser?.pubId) {
      // console.log(
      //   'UserNavigationBar routing to',
      //   selectedUser.name,
      //   selectedUser.pubId);
      navigate(`${userRouteBase}/${selectedUser.pubId}`);
    }
  }

  const handleChange = (event: any, selectedUser: any) => {
    // pause while hiding the autocomplete list etc
    pause(100).then(
      () => handleUserSearch(event, selectedUser));
  }

  return (
    <div style={{width: 300}}>
      {
        !allUsers?.length ?
        (
          <CircularProgress
            size={21}
            thickness={2}
            style={{
              marginLeft: '150px',
            }}
          />
        ) :
        (
          <Autocomplete
            // freeSolo
            {...props}
            sx={sx}
            id="user-search-autocomplete"
            clearOnEscape
            clearOnBlur
            options={
              allUsers || []
            }
            getOptionLabel={(option: any) =>
              option?.name
            }
            onChange={(event, selectedUser) =>
              handleChange(event, selectedUser)}
            // This complication seems to be the only way to set
            // a unique key to avoid React duplicate-key warnings
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.pubId}>
                  {option?.name}
                </li>
              );
            }}
            renderInput={
              (params) => (
                <>
                  <TextField
                    {...params}
                    id="user-search-TextField"
                    label="User Search"
                    margin="dense"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      id: 'user-search-input',
                      style: {borderRadius: '5px'}
                    }}
                  />
                </>
              )
            }
          />
        )
     }
    </div>
  )
};


// if (event.key === 'Enter') {
