import {gql} from "graphql-request";
import {saasFragment} from "../shared/Fragments";

export const appDriftFragment = `
fragment AppDrift on drift {
    client_saas {
        saas {
          ...Saas
        }
      }

      generic_user {
        name
        email
        pub_id
      }
      pub_id
      drift_time
      old_value
      new_value
      addendum
      app{
        pub_id
        display_name
      }
    }
    `

export const driftFragment = `
fragment Drift on drift {
client_saas {
        saas {
          ...Saas
        }
      }

      generic_user {
        name
        email
        pub_id
      }
      pub_id
      drift_time
      old_value
      new_value
      addendum
}
`
// Used on the authentication exception and privileged user drift drill down from "Company view"
export const driftsQuery = gql`
  query Drifts(
    $driftType: drift_type,
    $saasIdentifier: String = "",
    $driftFrom: timestamp
  ) {
    drift(
      where: {
        client_saas: {saas: {name: {_regex: $saasIdentifier}}},
        subject: {_eq: $driftType},
        drift_time: {_gt: $driftFrom},
        _or:[
          {suppressed_until:{_is_null: true}},
          {suppressed_until:{_lt:"now()"}}
        ],
      },
      order_by: {client_saas:{saas: {name: asc}}}
    ) {
      ...Drift
    }
  }
  ${driftFragment}
  ${saasFragment}
`;

// Used to get the app drifts for entire company from Discovery dashboard
// This will only get drifts for microsoft365 at present as the Salesforce drifts need to be treated differently in the UI
export const allAppDriftsQuery = gql`
  query AppDrifts (
    $driftFrom: timestamp
  ) {
    drift(
      where: {
        client_saas: {saas : {name:{_eq : "microsoft365"}}},
        subject: {_eq: APP_STATUS_CHANGE},
        drift_time: {_gt: $driftFrom},
        _or:[
          {suppressed_until:{_is_null: true}},
          {suppressed_until:{_lt:"now()"}}
        ],
      }
    ) {
      ...AppDrift
      }
  }
  ${appDriftFragment}
  ${saasFragment}
`;

// Drifts for a specific app are filtered for that app, and we also get drifts from all time
export const appDriftsQuery = gql`
  query AppDrifts (
    $app_pub_id: uuid
  ) {
    drift(
      where: {
        client_saas: {saas : {name:{_eq : "microsoft365"}}},
        subject: {_eq: APP_STATUS_CHANGE},
        app:{pub_id: {_eq: $app_pub_id}},
        _or:[
          {suppressed_until:{_is_null: true}},
          {suppressed_until:{_lt:"now()"}}
        ],
      }
    ) {
      ...AppDrift
      }
  }
  ${appDriftFragment}
  ${saasFragment}
`;

export const appDriftsForUser = gql`
  query AppDrifts (
    $user_pub_id: uuid
  ) {
    drift(
      where: {
        client_saas: {saas : {name:{_eq : "microsoft365"}}},
        subject: {_eq: APP_STATUS_CHANGE},
        generic_user:{pub_id: {_eq: $user_pub_id}},
        _or:[
          {suppressed_until:{_is_null: true}},
          {suppressed_until:{_lt:"now()"}}
        ],
      }
    ) {
      ...AppDrift
      }
  }
  ${appDriftFragment}
  ${saasFragment}
`;

export const addedAppDriftsQuery = gql`
  query AddedAppDrifts (
    $driftFrom: timestamp,
    $addendumFilter: jsonb_comparison_exp,
  ) {
    drift(
      where: {
        client_saas: {saas : {name:{_eq : "microsoft365"}}},
        subject: {_eq: APP_STATUS_CHANGE},
        drift_time: {_gt: $driftFrom},
        addendum: $addendumFilter,
        new_value:{_eq: "Enabled"}
        _or:[
          {suppressed_until:{_is_null: true}},
          {suppressed_until:{_lt:"now()"}}
        ],
      }
    ) {
      ...AppDrift
      }
  }
  ${appDriftFragment}
  ${saasFragment}
`;

export const addedAppDriftsQueryCount = gql`
  query AddedAppDriftsCount (
    $driftFrom: timestamp,
    $addendumFilter: jsonb_comparison_exp,
  ) {
    drift_aggregate(
      where: {
        client_saas: {saas : {name:{_eq : "microsoft365"}}},
        subject: {_eq: APP_STATUS_CHANGE},
        drift_time: {_gt: $driftFrom},
        addendum: $addendumFilter,
        new_value:{_eq: "Enabled"}
        _or:[
          {suppressed_until:{_is_null: true}},
          {suppressed_until:{_lt:"now()"}}
        ],
      }
    ) {
        aggregate{
          count
        }
      }
  }
`;

// This fragment is added to the user view query to include app drifts for a particular user
export const appDriftQueryPiece = `
  app_drifts: drifts(
    where: {
      _or:[
        {suppressed_until:{_is_null: true}},
        {suppressed_until:{_lt:"now()"}}
      ],
      subject: {_eq:"APP_STATUS_CHANGE"}
    },
    order_by: {drift_time: desc}
  ) {
    pub_id
    addendum
    old_value
    new_value
    drift_time
    client_saas{
      saas{
        ...Saas
      }
      nick_name
      pub_id
    }
  }
`;