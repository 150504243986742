import React, {FC, ReactNode, useCallback} from 'react';
import {useLocation} from 'react-router';
import {matchPath, useNavigate} from 'react-router';
import {LoadingScreen} from 'src/components/LoadingScreen';
import {getRoute, getSaasRoute, saasRoute, saasRouteFragment} from 'src/routes';
import {DefaultSelect} from 'src/components/common/DefaultSelect';
import {getConnectedSaases} from 'src/services/SaasService/Saases';
import {cacheKeys} from 'src/services/shared/serviceConstants';
import {SelectChangeEvent} from '@mui/material';
import {useLegacyQueryWithToken} from "src/hooks/TanstackHooks";
import {isEmptyString} from "src/utils/string";

interface props {
  className?: string;
}

export const SaasViewSelect: FC<props> = ({className}) => {
  const {
    data: saases,
    isLoading
  } = useLegacyQueryWithToken(
    [cacheKeys.connectedSaasList],
    getConnectedSaases,
  );

  const navigate = useNavigate();
  const {pathname} = useLocation()

  // why doesn't a simple const params = useParams work here?

  // Handling with and without a connection ID doesn't help
  // with selecting the correct saas when the url contains
  // no connectionId.  See commented out section below.
  const {params} =
    matchPath(
      {
        path: `${saasRoute}/:saasIdentifier/:connectionId`,
        end: true,
      },
      pathname
      // ) || matchPath(
      //   {
      //     path: `${saasRoute}/:saasIdentifier`,
      //     end: true,
      //   },
      //   pathname
    ) || {};

  // console.log({params});

  const optionsLoadingLabel = 'loading'
  const saasProviderOptions = useCallback(
    () => {
      let result = [];
      if (isLoading || !saases || !Array.isArray(saases)) {
        result = [{value: optionsLoadingLabel, label: optionsLoadingLabel}];
      }
      else {
        // console.log({saases});
        result = saases?.map(
          ({identifier, name, calculatedName, connectionId}: any) => ({
            value: `${identifier}/${connectionId}`,
            label: calculatedName,
          }),
        )
      }
      return result;
    }, [isLoading, saases]
  )

  const handleChangeSaaSProvider = useCallback(
    (event: SelectChangeEvent<unknown>, child: ReactNode) => {
      const nextSaas = event.target.value as string;
      console.log({nextSaas});
      navigate(
        isEmptyString(nextSaas) ?
          getRoute(saasRouteFragment) :
          getSaasRoute(nextSaas)
      );
    },
    [navigate]
  );

  return (saasProviderOptions()?.[0]?.value !== 'loading' ?
    <DefaultSelect
      placeholder="All SaaS"
      // placeholder=""
      defaultValue="" // fixes a warning

      // commented out temporary handling of with
      // or without connection ID. Doesn't help.
      value={
        // params ?
        //   `${params?.saasIdentifier}${
        //     params ?
        //       // @ts-ignore
        //       '/' + params?.connectionId :
        //       ''}`
        //   : ''
        params ?
          `${params?.saasIdentifier}/${params?.connectionId}`
          : ''
      }

      onChange={handleChangeSaaSProvider}
      label="SaaS Provider"
      options={saasProviderOptions()}
      className={className}
    />
    :
    <LoadingScreen size="1rem"/>
  );
};
