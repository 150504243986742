import {FC} from 'react';
import {MainContent} from 'src/components/MainLayout/MainContent';
import {MainLayoutFooter} from 'src/components/MainLayout/MainLayoutFooter';
import {Header} from 'src/components/Header/Header';
import {BuildPortalContext, PortalContext} from "../../hooks/PortalContext";
import {useUpgradeFeatureDialog} from "../common/UpgradeFeature/useUpgradeFeatureDialog";

export const MainLayout: FC = ({ children }: any) => {
  // Set up the feature upgrade dialog
  const {
    dialog: featureUpgradeDialog,
    setIsOpen: setFeatureDialogOpen,
  } = useUpgradeFeatureDialog({
    onCancel: ()=>{setFeatureDialogOpen(false)},
  })

  let {portalInfo, isLoading} = BuildPortalContext({
    setFeatureDialogOpen: setFeatureDialogOpen
  });

  if (isLoading || !portalInfo) {
    return <></>
  }

  return (
    <PortalContext.Provider value={portalInfo}>
      {featureUpgradeDialog}
      <Header />
      <MainContent>{children}</MainContent>
      <MainLayoutFooter />
    </PortalContext.Provider>
  );
};
