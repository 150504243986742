import {gql} from 'graphql-request';
import {connectionFragment, saasFragment, userCoreFragment} from '../shared/Fragments';

// omits users with blank names - used by user search
export const genericUsersQueryOmitNullNames = gql`
query GetGenericUsers {
  generic_user(
    where: 
    {latest_saas_users: {generic_user:{name: {_is_null: false}}}}, 
    order_by: {name: asc})
  {
    pub_id
    name
  }
}
`;

export const allGenericUsersQuery = gql`
query GetGenericUsers {
  generic_user(
    where: {latest_saas_users: {}}, 
    order_by: {name: asc})
  {
    pub_id
    name
  }
}
`;

export const userViewQuery = gql`
query GetUserView(
  $pubId: uuid
) {
  generic_user(where: {pub_id: {_eq: $pubId}}){
    name
    pub_id
    latest_saas_users{
      unique_id
      created_time
      ...UserCore
      
      # saas view version doesn't have this probably because 
      # it doesn't have to display the Saas logo and name
      saas{
        ...Saas
      }
      ...Connection
    }
  }
}
${saasFragment}
${connectionFragment}
${userCoreFragment}
`;
