
export const samplePlaceholderData = [
  {
    "id": "00000000-0000-0000-0000-000000000001",
    "name": "Jason Shaw",
    "email": "jason.shaw@kwimbee.org",
    "privilegeLevel": "Highly Privileged User",
    "domain": "Internal",
    "status": true,
  }];

export const samplePlaceholderColumns = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
},
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
  },{
    field: 'privilegeLevel',
    headerName: 'Privilege level',
    flex: 1,
  },{
    field: 'domain',
    headerName: 'Domain',
    flex: 1,
  },{
    field: 'status',
    headerName: 'Active',
    flex: 1,
  }];