import React from 'react';
import {UserTotalPanel} from "../UserTotalPanel";
import {riskTypeIdentifiers} from "../../../routes";
import {RiskRowBox} from "./RiskRowBox";
import {useLegacyQueryWithToken} from "../../../hooks/TanstackHooks";
import {cacheKeys} from "../../../services/shared/serviceConstants";
import {usePortalContext} from "../../../hooks/PortalContext";
import {FeatureCountProps} from "../../../types/FeaturePanelProps";
import {getPanelLink, performFeatureBasedQuery} from "../DashboardPanels/panelCommon";
import {getGhosts, getGhostsCount} from "../../../services/shared/Ghosts";

export interface GhostUserCountProps extends FeatureCountProps {
  sot: any;
  numberInCircle: number;
}
export const GhostUserCount = (props: GhostUserCountProps) => {
  const {sot, isFeatureOn, numberInCircle} = props;

  // This query is executed if the feature is turned off - it gets the count of inactive users
  const countQuery = useLegacyQueryWithToken(
    [cacheKeys.ghostsCount],
    getGhostsCount,
    {sot},
    {
      enabled: !isFeatureOn && !!sot  // Don't execute the query if there is no SoT
    }
  );

  // This query is executed if the feature is turned on - it gets the full list of inactive users
  const fullQuery = useLegacyQueryWithToken(
    [cacheKeys.ghosts],
    getGhosts,
    {sot},
    {
      enabled: isFeatureOn && !!sot  // Don't execute the query if there is no SoT
    }
  );

  // If there's a SoT then run the query, if not supply placeholder values to the UI
  const {isLoading, count, users, isError} = sot ? performFeatureBasedQuery(isFeatureOn, fullQuery, countQuery)
    : {
      isLoading : false,
      count : 0,
      users : [],
      isError: false,
  };

  const {isTrial} = usePortalContext();


  return (<RiskRowBox>
    <UserTotalPanel
      count={count}
      users={users}
      riskName="Ghost Users"
      riskDescription={<span><em>Users without an active account in your source of truth, <strong>{sot?.name}</strong></em></span>}
      viewAllUrl={getPanelLink(riskTypeIdentifiers.ghosts, count, !isTrial && isFeatureOn)}
      numberInCircle={numberInCircle}
      largeMode={false}
      featureEnabled={isFeatureOn}
      isLoading={isLoading}
      isError={isError}
    />
  </RiskRowBox>)
};
