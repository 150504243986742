import {Box, Typography} from "@mui/material";
import React from "react";
import {DefaultTableContainer} from "../DefaultTable";
import {CustomFooter, DefaultGrid, GridHeadingWithExport, smallerRowsPerPageOptions} from "../Tables/DefaultGrid";
import {dummyUserData} from "../../../pages/DashboardPage/DashboardWidgets/dummyUserData";
import {FeatureUpgradeButton} from "./FeatureUpgradeButton";
import {h5FontSize} from "../../../theme";
import {samplePlaceholderColumns, samplePlaceholderData} from "./SampleData";

// Provides a "placeholder" table for grids that don't have a required feature turned on
export const FeatureUpgradeGrid = (
  {
     featureName,       // Display text for above the button
     cols,              // Column definition for the grid.  It will use a default if not supplied.
     rows,              // Rows for the grid.  It will use a default if not supplied.
     showFooter=true    // Whether to show the grid footer or not
  }:{
    featureName: string,
    cols?:any,
    rows?:any,
    showFooter?:boolean
  }) => {
  return <DefaultTableContainer shouldUseTableContainer={false} >
    <Box sx={{position: 'relative'}}>
      <Box sx={{            // This CSS positions the upgrade message and button over the top of the grid
        position: 'absolute',
        top:"calc(50% - 40px)",
        textAlign: "center",
        width: "100%"}}>
          <Typography sx={{fontSize: h5FontSize}} > {featureName}</Typography>
          <FeatureUpgradeButton />
      </Box>
      <Box
        sx={{   // This CSS blurs the underlying grid and makes it non-clickable
          filter: 'blur(8px)',
          opacity: '0.5',
          pointerEvents: 'none',
        }}
      >
        <DefaultGrid
          rows={rows ?? samplePlaceholderData}          // If rows are supplied use them, otherwise use the default
          columns={cols ?? samplePlaceholderColumns}    // If columns are supplied use them, otherwise use the default
          getRowId={(row: any) => row.id}
            // @ts-ignore
          defaultPageSize={dummyUserData.length}
          pageSizeOptions={smallerRowsPerPageOptions}
          isLoading={false}
          toolbar={() =>
            <GridHeadingWithExport
              heading={featureName}
              shouldShowExportButton={false}
              shouldShowBackToPrevious={false}
            />}
          footer={() => showFooter ? CustomFooter({}) : <></>}
        />
      </Box>
    </Box>
  </DefaultTableContainer>
}