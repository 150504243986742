import {ICompanyAccess} from "../types/Company";
import {createContext, useContext} from "react";
import {IPortalUser} from "../types/PortalUser";
import {useAuth0Token} from "./Auth0Hooks";
import {useClientInfo} from "./ClientHooks";
import {useLegacyQueryWithToken} from "./TanstackHooks";
import {cacheKeys} from "../services/shared/serviceConstants";
import {getPortalUser} from "../services/PortalUsers/PortalUserQueries";
import {planTypes, portalUserRoles} from "../utils/constants";

type PortalInfo = {
  clientInfo: ICompanyAccess,
  loggedInUser: {userInfo: IPortalUser, highestPrivilege: string},
  isTrial: boolean,
  featureDialog?: {
    open: () => void,
    close: () => void,
  }
}
export const PortalContext = createContext<PortalInfo | null>(null);

export const usePortalContext = () => {
  const ctx = useContext(PortalContext);

  if (!ctx) {
    throw new Error(
      "usePortalContext has to be used within <PortalContext.Provider>"
    );
  }

  return ctx;
};

export const BuildPortalContext = ({setFeatureDialogOpen}:{setFeatureDialogOpen : Function}): {portalInfo: PortalInfo | undefined, isLoading: boolean} => {
  // Get the Auth0 user because we need the user_id/sub to get the portal user
  const {user, isLoading: tokenLoading} = useAuth0Token();

  // The client is still set by the JWT token (from the Auth0 app_metadata) for now
  // In the future step this will be picked from a "client chooser" dropdown
  const {data: clientInfo, isLoading: clientInfoLoading} = useClientInfo();

  // Get the logged in user using the Auth0 user_id (which is in the "sub" field)
  const {data: userInfo, isLoading: userInfoLoading} = useLegacyQueryWithToken(
    [cacheKeys.loggedInUser],
    getPortalUser,
    {
      external_id: user?.sub
    },
    {
      enabled: !tokenLoading,
    });

  // Return with the loading state if the above queries are still loading
  if (clientInfoLoading || userInfoLoading || tokenLoading) {
    return {portalInfo: undefined, isLoading: true};
  }

  // Pages/Features in the portal are restricted by the user's role
  // If a user has ANY role of a higher permission, they can see the higher permission pages/features
  // Start by defaulting them to a "user"
  let highestPrivilege = portalUserRoles.user;

  if (userInfo.clientRoles.some((cr: any) => cr.role === portalUserRoles.admin)) {
    // If they're an "admin" (Super Admin) in any role, they can see the admin pages/features
    highestPrivilege = portalUserRoles.admin;
  } else if (userInfo.clientRoles.some((cr: any) => cr.role === portalUserRoles.orgAdmin)) {
    // Otherwise, If they're an "orgAdmin" (Account Admin) in any role, they can see the orgAdmin pages/features
    highestPrivilege = portalUserRoles.orgAdmin;
  }

  let portalContext = {
    clientInfo:clientInfo,
    loggedInUser: {userInfo: userInfo, highestPrivilege: highestPrivilege},
    isTrial: clientInfo.planType === planTypes.Trial,
    featureDialog: {
      open: () => setFeatureDialogOpen(true),
      close: () => setFeatureDialogOpen(false),
    }
  }

  let isLoading : boolean = (clientInfoLoading|| userInfoLoading);

  return {portalInfo: portalContext, isLoading : isLoading};
}