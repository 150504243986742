import React, {useCallback, useState} from 'react';
import {
  CustomFooter,
  DefaultGrid,
  GridHeadingWithExport,
  renderYesNoFromBoolean,
} from "../../../../components/common/Tables/DefaultGrid";
import {IClientRole, IPortalUser} from "../../../../types/PortalUser";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {DefaultButton} from "../../../../components/common/DefaultButton";
import {EditPortalUserDialog} from "./EditPortalUserDialog";
import {renderPortalUserEllipsis} from "./PortalUserActionMenu";
import {useMutationWithAccessToken} from "../../../../hooks/TanstackHooks";
import {cacheKeys} from "../../../../services/shared/serviceConstants";
import {useQueryClient} from "@tanstack/react-query";
import {updatePortalUser, createPortalUser} from "../../../../services/PortalUsers/PortalUserQueries";
import {useErrorDialog} from "../../../../components/common/Dialog/ErrorDialog";
import {ICompany} from "../../../../types/Company";
import {usePasswordSetDialog} from "./usePasswordSetDialog";
import {Auth0UsernamePasswordConnection, portalUserRoles} from "../../../../utils/constants";
import {Pill} from "../../../../components/common/PIll";
import {compactInfoFontSize, theme} from "../../../../theme";
import {HtmlTooltip} from "../../../../components/common/HtmlTooltip";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {usePillStyles} from "../../../../components/common/PIll/styles";
import clsx from "clsx";


export const PortalUsersComponent = ({
  portalUsers,
  userRole,
  companies
   }: {
    portalUsers: IPortalUser[],
    userRole: string,
    companies: ICompany[],
}) => {
  const minRowsPerPageOption = 100
  const rowsPerPageOptions = [minRowsPerPageOption]

  const queryClient = useQueryClient();
  const styleProps = {pillColor: theme.palette.text.secondary, pillWidth: '100px'};
  const { pillStyle } = usePillStyles(styleProps);

  const [isUpdateUserDialogOpen, setIsUpdateUserDialogOpen] = useState(false);
  const [isUserUpdatePending, setIsUserUpdatePending] = useState(false);

  const [currentPortalUser, setCurrentPortalUser] = useState<IPortalUser>();

  // Construct the error dialog.
  const {
    dialog: errorDialog,
    setIsOpen: setIsErrorDialogOpen,
    setErrorMessage,
  } = useErrorDialog({
    onDismiss: ()=>{},
  })

  // Construct the password dialog
  // It only appears if "Force password refresh" isn't checked when creating a new user
  const {
    dialog: passwordSetDialog,
    setIsOpen: setIsPasswordSetDialogOpen,
    setPassword: setPasswordInDialog,
  } = usePasswordSetDialog({
    onConfirm: ()=>{
      // Remove the password so it doesn't appear next time
      setPasswordInDialog('');
      },
  })


  const {
    mutate: doUpdateUser,
  } = useMutationWithAccessToken({
    mutation: updatePortalUser,
    tanstackOptions: {
      onSuccess: () => {
        // Invalidating the cache will cause the users to get re-queried and updated accordingly
        queryClient.invalidateQueries([cacheKeys.portalUsers]);
        setIsUpdateUserDialogOpen(false);
        setIsUserUpdatePending(false);
        setIsErrorDialogOpen(false);
        setErrorMessage('');
      },
      onError: (error: any) => {
        setIsErrorDialogOpen(true);
        setErrorMessage("Could not update user");
        setIsUpdateUserDialogOpen(false);
        setIsUserUpdatePending(false);
      }
    }
  });

  const {
    mutate: doCreateUser,
  } = useMutationWithAccessToken({
    mutation: createPortalUser,
    tanstackOptions: {
      onSuccess: (response: any) => {
        // Invalidating the cache will cause the users to get re-queried and updated accordingly
        queryClient.invalidateQueries([cacheKeys.portalUsers]);
        setIsUpdateUserDialogOpen(false);
        setIsUserUpdatePending(false);
        setCurrentPortalUser(undefined);

        if (response.insert_user.temp_password) {
          setIsPasswordSetDialogOpen(true);
          setPasswordInDialog(response.insert_user.temp_password);
        }

      },
      onError: (error: any) => {
        setIsErrorDialogOpen(true);
        if (error.response && error.response.errors && error.response.errors.length > 0) {
          setErrorMessage(error.response.errors[0].message);
        }
        else {
          setErrorMessage("Could not add user");
        }

        setIsUpdateUserDialogOpen(false);
        setIsUserUpdatePending(false);
      }
    }
  });

  const onNewUser = () => {
    setCurrentPortalUser(undefined);
    setIsUpdateUserDialogOpen(true)
  }

  const onUpdateUserMenuClick = (user: IPortalUser) => {
    setCurrentPortalUser(user);
    setIsUpdateUserDialogOpen(true)
  }

  const onCancelUpdateUser = () => {
    setIsUserUpdatePending(false);
    setCurrentPortalUser(undefined);
    setIsUpdateUserDialogOpen(false);
    setCurrentPortalUser(undefined);
  }

  const onConfirmUpdateUser = (user: any) => {
    setIsUserUpdatePending(true);
    if (currentPortalUser) {
      // @ts-ignore
      doUpdateUser({portalUser: user});
    } else {
      // @ts-ignore
      doCreateUser({portalUser: user});
    }
  }
  const columns = useCallback(
    (): GridColDef [] => {
      const getCompanyNameFromID = (companyId: string) => {
        return companies.find((c:any) => c.id === companyId)?.name;
      }

      return [
        {
          headerName: 'Name',
          field: 'name',
          minWidth: 200,
          flex: 3,
          sortable: true,

        },
        {
          headerName: 'Email',
          field: 'email',
          flex: 3,
          sortable: true,
        },
        {
          headerName: 'Type',
          field: 'connectionType',
          flex: 1,
          sortable: true,
          renderCell:(params: GridRenderCellParams) => {
            let isSSO = params.row.connectionType !== Auth0UsernamePasswordConnection;
            let pillColor = isSSO ? theme.more.andyBlue : theme.more.andyGreen;
            let pillTitle = isSSO ? "SSO" : "PWD";
            let pillDescription = isSSO ? "Detexian SSO authentication" : "Password authentication";
            return (<Pill
              pillWidth="82px"
              pillColor={pillColor}
              title={pillTitle}
              hoverText={pillDescription}>
            </Pill>)
          },
        },
        {
          headerName: 'Company',
          field: 'companyName',
          flex: 4,
          sortable: false,
          renderCell:(params: GridRenderCellParams) => {
            let clientRoles = params.row.clientRoles;
            let moreIndicator = <></>

            // If there's more than one, add brackets with the count
            if (clientRoles.length > 1) {
              let toolTipContent = clientRoles.map((clientRole: IClientRole) => {
                return <div key={clientRole.client_id} style={{display: 'flex', color: theme.palette.text.primary}}>
                  <div style={{flexGrow: '2'}}>{getCompanyNameFromID(clientRole.client_id)}</div>
                  <div>
                     <span className={clsx(pillStyle)}
                          style={{
                           marginLeft: theme.spacing(1),
                           width: getPortalUserRole(clientRole.role) === portalUserRoles.user ? '50px' : '100px',
                         }}>
                       {getPortalUserRole(clientRole.role)}
                     </span>
                  </div>
                </div>
              });

              moreIndicator = <HtmlTooltip
                title={
                  <React.Fragment>
                        <span
                          style={{
                            fontSize: compactInfoFontSize,
                          }}
                        >
                          {toolTipContent}
                        </span>
                  </React.Fragment>
                }
                sx={{maxWidth: '800px'}}
              ><AddCircleOutlineIcon sx={{height: '18px', color: theme.palette.text.secondary}} data-testid={`additional-role-indicator-${params.row.id}`} />
              </HtmlTooltip>
            }
            // We will show the first user role on the grid, as most users will only have one
            return (clientRoles && clientRoles.length > 0) ? <>
              {getCompanyNameFromID(clientRoles[0].client_id)}
              <span className={clsx(pillStyle)}
                    style={{
                      marginLeft: theme.spacing(1),
                      width: clientRoles[0].role === portalUserRoles.user ? '50px' : '100px',
                  }}>{getPortalUserRole(clientRoles[0].role)} </span>
              {moreIndicator}
            </> : <></>;
          },
        },
        {
          headerName: 'Disabled',
          field: 'disabled',
          flex: 1,
          sortable: true,
          valueGetter: (params) => renderYesNoFromBoolean(params),
        },
        {
          headerName: '',
          field: 'action',
          renderCell: (params: GridRenderCellParams) =>
            renderPortalUserEllipsis({
              userRole: userRole,
              portalUser: params.row,
              onUpdateClick: onUpdateUserMenuClick
            }),
          sortable: false,
          disableExport: true,
          disableReorder: true,
          filterable: false,
          disableColumnMenu: true,
          flex: 1,
        },
      ];
    },
    [userRole, companies, pillStyle]
  );

  const [paginationModel, setPaginationModel] = useState({
    pageSize: minRowsPerPageOption,
    page: 0,
  });

  return (<>
      <EditPortalUserDialog
        portalUser={currentPortalUser}
        isOpen={isUpdateUserDialogOpen}
        setIsOpen={setIsUpdateUserDialogOpen}
        isSpinning={isUserUpdatePending}
        onCancel= {onCancelUpdateUser}
        onConfirm={onConfirmUpdateUser}
        userRole={userRole}
        companies={companies}
      />
      {errorDialog}
      {passwordSetDialog}
        <DefaultGrid
          rows={portalUsers}
          getRowId={row => row.id}
          columns={columns()}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={rowsPerPageOptions}
          length={portalUsers?.length}
          minRowsPerPageOption={minRowsPerPageOption}
          initialState={{
            sorting: {
              sortModel: [{
                field: "name",
                sort: "asc",
              }],
            },
          }}
          toolbar={() =>
            <GridHeadingWithExport
              heading="Users"
              shouldShowExportButton={false}
              shouldShowBackToPrevious={false}
              tableDescription={<><DefaultButton
                sx={{
                  width: '7rem',
                  fontWeight: '500',
                  float: 'right',
                  marginTop: '-32px',
                }}
                autoFocus
                variant="contained"
                onClick={onNewUser}
              >
                Add user
              </DefaultButton>
              <p>Control how users access the Detexian portal.</p>
            </>}
            />}
          footer={() => CustomFooter({})}
        />
    </>
  )
};


// Converts the "Role" - admin, org_admin, user
// to a presentable version for the grid cell
const getPortalUserRole = (
  role: string,
) => {
  switch(role){
    case portalUserRoles.admin:
      return "Super Admin";
    case portalUserRoles.orgAdmin:
      return "Account Admin";
    case portalUserRoles.user:
      return "User";
    default:
      return "-";
  }
};
