import {useCallback} from 'react';
import {useComponentForQueryResult} from 'src/hooks/UseComponentForQueryResult';
import {getPrivilegedAccesses} from 'src/services/PrivilegedAccessService';
import {cacheKeys} from 'src/services/shared/serviceConstants';
import {PrivilegedAccessComponent} from './PrivilegedAccessComponent';
import {useLegacyQueryWithToken} from "src/hooks/TanstackHooks";

export const PrivilegedAccessPage = () => {

  const documentTitle = 'Privileged Access - Detexian';
  document.title = documentTitle;

  const queryResult = useLegacyQueryWithToken(
    [cacheKeys.privilegeSummary], // cache key
    getPrivilegedAccesses,
  );

  const pageLoadFunction = useCallback(
    () => (
      <PrivilegedAccessComponent privilegeSummary={queryResult.data} />
    ),
    [queryResult.data]
  );

  return useComponentForQueryResult({
    queryResult: queryResult,
    pageLoadFunction,
    userErrorMessage: 'error accessing Privilege Summary',
  })
};

