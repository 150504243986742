import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import React from 'react';
import {h5FontSize, theme} from "../../../theme";
import {DefaultTableContainer} from "../../../components/common/DefaultTable";
import {DefaultTableNumberCell} from "../../../components/common/DefaultTable/DefaultTableNumberCell";
import {useLegacyQueryWithToken} from "../../../hooks/TanstackHooks";
import {cacheKeyForDiscoveryAppInfo} from "../../../services/shared/serviceConstants";
import {getAppInfo} from "../../../services/Discovery/Discovery";
import {TablePanelLabel} from "../../../components/common/Tables/Utilities";
import {useDefaultTableContainerStyles} from "../../../components/common/DefaultTable/styles";


const tableHeadCellStyle = {
  color: theme.palette.text.primary,
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  whiteSpace: 'pre-line',
  textAlign: 'center',
  lineHeight: 1,
}

const tableCellStyle = {
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
};

export const AppInfoPanel = ({appPubId }: {appPubId:string}) => {

  const {
    tableContainerStyles,
  } = useDefaultTableContainerStyles();

  const appInfoQuery = useLegacyQueryWithToken(
    [cacheKeyForDiscoveryAppInfo(appPubId)],
    getAppInfo,
    {appPubId: appPubId},
  );

  return (<DefaultTableContainer shouldUseTableContainer={false}>
    <TablePanelLabel
      shouldShowBackToPrevious={true}
      heading={<Box
        sx={{
          marginBottom: '3px',
          fontSize: `${h5FontSize}`,
        }}
      >
        {appInfoQuery.data?.display_name}
      </Box>} />
    <TableContainer
      className={tableContainerStyles}
      component={Paper}
    >
    <Table>
      <TableHead
        sx={{
          backgroundColor: theme.more.summaryHeadingBackgroundColor,
        }}
      >
        <TableRow>
          <TableCell
            sx={tableHeadCellStyle}
          >
            IT-Approved
          </TableCell>
          <TableCell
            sx={tableHeadCellStyle}
          >
            Write access
          </TableCell>
          <TableCell
            sx={tableHeadCellStyle}
          >
            Email access
          </TableCell>
          <TableCell
            sx={tableHeadCellStyle}
          >
            Calendar access
          </TableCell>
          <TableCell
            sx={tableHeadCellStyle}
          >
            File access
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {appInfoQuery.isLoading ?
          <TableRow>
            <TableCell
              sx={{
                maxWidth: '170px',
                minWidth: '110px',
                textAlign: 'center',
              }}
              colSpan={6}
            ><CircularProgress size={31}/> </TableCell>
          </TableRow>
        :
        <TableRow>

          <DefaultTableNumberCell
            sx={tableCellStyle}
            primaryText={appInfoQuery.data?.is_enterprise ? "Yes" : "No"}
            ariaLabel="IT-Approved"
          />
          <DefaultTableNumberCell
            sx={tableCellStyle}
            primaryText={appInfoQuery.data?.access?.write_access ? "Yes" : "No"}
            ariaLabel="Write access"
          />
          <DefaultTableNumberCell
            sx={tableCellStyle}
            primaryText={appInfoQuery.data?.access?.email_access ? "Yes" : "No"}
            ariaLabel="Email access"
          />
          <DefaultTableNumberCell
            sx={tableCellStyle}
            primaryText={appInfoQuery.data?.access?.calendar_access ? "Yes" : "No"}
            ariaLabel="Calendar access"
          />
          <DefaultTableNumberCell
            sx={tableCellStyle}
            primaryText={appInfoQuery.data?.access?.file_access ? "Yes" : "No"}
            ariaLabel="File access"
          />
        </TableRow>
        }
      </TableBody>
    </Table>
    </TableContainer>
  </DefaultTableContainer>);
}