import {gql} from 'graphql-request';
import {activityLogSubjects} from 'src/services/ActivityLog/ActivityLogTransform';
import {saasFragment} from '../shared/Fragments';

export const mfaDisabledFilter = `
  {_and: [
    {subject: {_eq: "MFA_CHANGE"}},
    {
      _and: [
        {subject: {_eq: "MFA_CHANGE"}},
        {new_value: {_neq: "true"}},
      ]
    }
  ]},
`;

// These values differ from userStatusStrings by case
// i.e. userStatusStrings are e.g. 'Enabled"
const driftUserStatusStrings = {
  enabled: 'enabled', // haven't actually shouldIncludeInternal this one
  deleted: 'deleted',
  disabled: 'disabled',
  noData: '-',
}

export const onBoardingFilter = `
  {
    _and: [
      {_or: [
        {old_value: {_ilike: ${driftUserStatusStrings.deleted}}},
        {old_value: {_ilike: ${driftUserStatusStrings.disabled}}},
        {old_value: {_ilike: "-"}},
        {old_value: {_ilike: ""}}
      ]},
      {subject: {_eq: ${activityLogSubjects.statusChange}}}
    ]
  },
`;
export const defaultFilter = `
      {subject: {_neq: ${activityLogSubjects.appStatusChange}}},
`

// This filter is essentially getting all drifts, but it needs to be limited to
// the type of drifts that the client has a feature enabled for
export const defaultFeaturedFilter = (features: any) => {
  let enabledDrifts = [];

  // Add the drifts types to the array that are enabled
  if (features.activity) {
    enabledDrifts.push(activityLogSubjects.userActivity)
  }
  if (features.mfa) {
    enabledDrifts.push(activityLogSubjects.mfaChange)
  }
  if (features.privileges) {
    enabledDrifts.push(activityLogSubjects.privilegeChange)
  }
  if (features.user_status) {
    enabledDrifts.push(activityLogSubjects.statusChange)
    enabledDrifts.push(activityLogSubjects.newUser)
  }

  // Build the query string based on features that are enabled
  // Should be in the format {subject: {_in: [USER_STATUS, MFA_CHANGE, ...]}}
  if (enabledDrifts.length > 0) {
    return `{subject: {_in: [${enabledDrifts.join(',')}]}}`
  }
  // If there are no features turned on, then the grid shouldn't query at all
  console.error('No features enabled when trying to access the drift grid');
}

export const offBoardingFilter = `
  {
    _and: [
      {old_value: {_ilike: ${driftUserStatusStrings.enabled}}},
      {subject: {_eq: ${activityLogSubjects.statusChange}}}
    ]
  },
`;

export const userActivityFilter = `
  {
    _and: [
      {subject: {_eq: ${activityLogSubjects.userActivity}}}
    ]
  },
`;

export const privilegeChangeFilter = `
  {subject: {_eq: ${activityLogSubjects.privilegeChange}}},
`;

export const activityLogQuery = (
  filter: any
) => gql`
query ActivityLog (
  $startDate: timestamp,
  $saasIdentifier: String = "",
  $userPubIdBoolExp: generic_user_bool_exp,
) {
  drift(
    where: {
      _and: [
        {drift_time: {_gte: $startDate}},
        {client_saas:{saas: {name: {_regex: $saasIdentifier}}}},
        {generic_user: $userPubIdBoolExp},
        ${filter}
      ]
    },
    #offset: 0, 
    #limit: 25000,
    order_by: {drift_time: desc}
  ) {
    pub_id
    client_saas {
        saas {
          ...Saas
        }
    }
    generic_user {
      name
      email
      pub_id
    }
    subject
    old_value
    new_value
    drift_time
    part
    # not found. why? drift_id
    #addendum
  }
}${saasFragment}`;

