import {gql} from 'graphql-request';
import {getExternalShares} from 'src/services/shared/ExternalShareService';
import {getMailForwards} from 'src/services/shared/MailForward';
import {transformSaasDetails} from 'src/services/SaasService/Transforms';
import {nullCollapsableSaasPubId} from 'src/services/shared/nullCollapsableUtilities';
import {ISingleSaasViewData} from 'src/types/Saas';
import {getDLPs} from '../shared/DLPService';
import {userStatusStrings} from 'src/utils/constants';
import {saasFragment, userCoreFragment} from '../shared/Fragments';
import {doAuthenticatedQuery} from "src/services/shared/doQuery";

export const saasViewQuery = gql`
  query GetSaasView(
    $saasName: String, 
    $connectionIdBoolExp: client_saas_bool_exp
  ) {
    saas(
      where: {
        name: {_eq: $saasName},
        #client_saas {
        #  pub_id: $connectionIdBoolExp
        #}
      }
    ) {
      ...Saas
      
      privilege_levels{
        privilege_level
        enabled
        # no_mfa
#        inactive_user
      }
      
      latest_saas_users(
        where:{
          status: {_eq: ${userStatusStrings.enabled}}
          connection: $connectionIdBoolExp,
        },
        order_by: {privilege_level: asc}
      ) {
        unique_id
        
        ...UserCore

#       inactive_60days
        created_time

        # User View version doesn't have the following

        domain # to identify external users

        # This might only be needed to provide a user link
        # i.e. the semi-dodgy, legacy hasUserUrl mechanism
        generic_user{
          name
          email
          pub_id
        }
      }
    }
  }
  ${saasFragment}
  ${userCoreFragment}
`;

// For Single SaaS View
export const getSaasDetails = async ({
  accessToken,
  parameters,
  signal,
}: {
  accessToken: string,
  parameters: any,
  signal: any,
}) => {
  let variables: any;
  let saasIdentifier;
  let connectionId;
  try {
    saasIdentifier = parameters.saasIdentifier;
    connectionId = parameters.connectionId;

    // console.log('getSaasDetails', {saasIdentifier}, {connectionId});

    variables = {
      saasName: saasIdentifier,
      // connectionIdBoolExp: connectionId ? {_eq: connectionId} : {}
      connectionIdBoolExp: nullCollapsableSaasPubId(connectionId),
    }
  }
  catch (error: any) {
    console.error(
      'getSaasDetails: error creating query variables',
      error?.message
    )
  }

  console.log('Saas View main query', {variables})

  const theSaasViewQuery = async (client: any) =>
    await client.request(
      saasViewQuery, variables
    );

  let saasDetails;
  try {
    saasDetails = await doAuthenticatedQuery(
      theSaasViewQuery, accessToken, signal
    );
  }
  catch (error) {
    console.error('saasViewQuery failed')
    throw (error)
  }

  // console.log({saasDetails});

  const bigQueryResult = await transformSaasDetails(saasDetails);

  // these queries all do the error handling and the transform
  const externalMailForwards = await getMailForwards(
    accessToken, {saasIdentifier, connectionId}, signal
  );

  const riskPolicyEvents = await getDLPs(
    accessToken,
    {saasIdentifier, connectionId}, // signal
  );

  const externalShares = await getExternalShares(
    accessToken, {saasIdentifier, connectionId}
  );

  const result: ISingleSaasViewData = {
    ...bigQueryResult,
    externalMailForwards,
    riskPolicyEvents,
    externalShares,
  };
  return result;
};




/* To speed debugging and development, avoiding the whole query
  const saasDetails = {
    dlp: [],
    file_share: [],
    saas: [
      {
        description: "SalesForce",
        drifts: [],
        latest_saas_users: [],
        name: 'salesforce',
        privilege_levels: [],
        pub_id: '23e464d1-c31d-4bdf-a7fc-ceb2a181b9b8',
      }
    ],
    mail_forward: [],
    mfaDisabled: [],
  }
 */
