import {useCallback} from 'react';
import {UserAccessExceptionSummaryTable} from 'src/pages/UserAccessExceptionsPage/UserAccessExceptionSummaryTable';
import {useComponentForQueryResult} from 'src/hooks/UseComponentForQueryResult';
import {cacheKeys} from 'src/services/shared/serviceConstants';
import {getUserAccessExceptions} from 'src/services/UserAccessExceptions/UserAccessExceptions';
import {getConnectedSaases} from 'src/services/SaasService/Saases';
import {useLegacyQueryWithToken} from "src/hooks/TanstackHooks";
import {useClientInfo} from "../../hooks/ClientHooks";


export const UserAccessExceptionsSummaryPage = () => {
  const documentTitle = 'User-Access Exceptions - Detexian';
  document.title = documentTitle;

  const { data: clientInfo } = useClientInfo();

  const {data: connectedSaases} = useLegacyQueryWithToken(
    [cacheKeys.connectedSaasList],
    getConnectedSaases,
  )

  const queryResult = useLegacyQueryWithToken(
    [cacheKeys.userAccessExceptions],
    getUserAccessExceptions,
      {sot: clientInfo?.sourceOfTruth},
    {
      // Only run the query if there are connectedSaases and a source of truth
        enabled: !!(connectedSaases && clientInfo && clientInfo.sourceOfTruth)
    }
  );

  const pageLoadFunction = useCallback(
    () => {
      return (
          <UserAccessExceptionSummaryTable
            saasesExceptions={queryResult.data}
            connectedSaases={connectedSaases}
          />
      );
    },
    [connectedSaases, queryResult.data]
  );

 return useComponentForQueryResult({
    queryResult: queryResult,
    pageLoadFunction,
    userErrorMessage: 'error accessing user access exceptions',
  })
};
