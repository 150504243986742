import {FC} from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {Typography} from '@mui/material';
import {DtxSpacer} from 'src/components/common/DtxSpacer';
import {useDashboardLayoutHeaderAccountStyles} from 'src/components/MainLayout/styles';
import {useAuth0Token} from 'src/hooks/Auth0Hooks';

export const Account: FC = () => {
  const { headerAccount } = useDashboardLayoutHeaderAccountStyles();

  // const {user} = useAuth0();
  const {user} = useAuth0Token();
  // console.log({user});

  // Get the company id for display - a bit fragile, it would seem
  let companyName = '';
  if (process.env.REACT_APP_SHOULD_SHOW_COMPANY_NAME === '1') {
    const claims = user?.['https://portal.detexian.com/jwt/claims'];
    // console.log({claims});
    companyName = claims?.['company-name'] || '';
    // console.log({companyName});
  }
  else {
    console.log('hiding Company Name');
  }

  return (
    <div className={headerAccount}>
      {user && <Typography>{companyName}</Typography>}
      <DtxSpacer orientation="vertical" />
      <AccountCircleIcon color="primary" />
      {user && <Typography>{user.name}</Typography>}
    </div>
  );
};

