import {useEffect} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useAuth0Token} from 'src/hooks/Auth0Hooks';
import {getAllSaas, getConnectedSaases} from 'src/services/SaasService/Saases';
import {
  cacheKeys,
} from 'src/services/shared/serviceConstants';
import {useLegacyQueryWithToken} from "src/hooks/TanstackHooks";
import {useClientInfo} from "../../hooks/ClientHooks";

// Prefetch as much as feasible

export const BaseDataLoader = () => {
  // console.log('BaseDataLoader running');
  const queryClient = useQueryClient();

  // fetch and cache the user saas list
  const {
    data: clientSaasList,
    isLoading
  } =
  useLegacyQueryWithToken(
    [cacheKeys.connectedSaasList],
    getConnectedSaases
  );

  const {
    data: clientInfo,
  } = useClientInfo();

  const {token} = useAuth0Token();

  useEffect(()=> {
    const readyToPrefetch = !!token && !!queryClient;
    // console.log('useEffect', {token}, {queryClient}, {readyToPrefetch});
    if (readyToPrefetch) {
      // prefetch all SaaSes
      queryClient.prefetchQuery(
        [`${cacheKeys.allSaasList}`], // omit null user names
        () => getAllSaas(token)
      );
    }
   }, [clientSaasList, token, isLoading, queryClient, clientInfo]
  );

  return null;
};
