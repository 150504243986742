import {gql} from 'graphql-request';
import {saasFragment} from 'src/services/shared/Fragments';

export const connectedSaasesQuery = gql`
  query GetConnectedSaases {
    client_saas (
      where: {saas: {}}, # filter out disabled SaaSes
      order_by: {saas: {name: asc}}
    ) {
      saas  {
        ...Saas
      }
      pub_id # the connection id
      nick_name
    }
  }
  ${saasFragment}
`;

export const connectedSaasesDetailQuery = gql`
  query GetConnectedSaasDetails {
    client_saas (
      where: {saas: {}}, # filter out disabled SaaSes
      order_by: {saas: {name: asc}}
    ) {
      saas  {
        ...Saas
      }
      secret_version {
        version
        type
      }
      pub_id # the connection id
      nick_name
    }
  }
  ${saasFragment}
`;

export const getAllSaasQuery = gql`
  query AllSaas {
    saas (order_by: {name: asc}) {
      pub_id
      name
      description
    }
  }
`;
