import {gql} from 'graphql-request';
import {saasFragment} from "../shared/Fragments";

export const singlePortalUserQuery = gql`
query SinglePortalUser($external_id: String) {
    cfg_user(where:{external_id: {_eq:$external_id}}){
          pub_id
          name
          email
          is_disabled
          connection_type
          user_roles {
            client {
              pub_id
              name
              has_access
              trial_remaining_days
              plan_type
              slug
              is_disabled
              configuration
              source_of_truth_saas{
                ...Saas
              }
            }
            role
          }
      }
}${saasFragment}`;

// Gets a list of portal users that the logged in user has access to
export const portalUserQuery = gql`
query ListPortalUsers($client_id: uuid) {
    list_users(client_id: $client_id){
          id
          name
          email
          disabled
          connection_type
          client_roles {
            client_id
            role
          }
      }
}`;

// Mutation for updating a portal user
export const updatePortalUserMutation = gql`
mutation UpdatePortalUser($client_id: uuid, $req:UpdateUserRequest!){
  update_user(client_id: $client_id, req:$req) {
    name
    email
  }
}`;

export const createPortalUserMutation = gql`
mutation CreatePortalUser($client_id: uuid, $req:CreateUserRequest!){
  insert_user(client_id: $client_id, req:$req) {
    temp_password
  }
}`;