export const cacheKeys = {
  connectedSaasList: 'connectedSaasList',
  connectedSaasDetailsList: 'connectedSaasDetailsList',
  subscriptionDetails: 'subscriptionDetails', // Azure Marketplace
  allSaasList: 'allSaasList',
  filteredAllSaasList: 'filteredAllSaasList', // Temporary SSPM-999: Filters out Gsuite on the Admin page only.
  clientInfo: 'clientInfo',
  dashboard: 'dashboard',
  companyDomains: 'companyDomains',
  discovery: {
    mock: 'mock',
    appSummary: 'appSummary',
    userAppCounts: 'userAppCounts',
    usersForApp: 'userForApp',
    appsForUser: 'appsForUser',
    appsForGenericUser: 'appsForGenericUser',
    appInfo: 'appInfo'
  },
  dailyDrifts:{
    mfa: 'dailyMFADrifts',
    inactiveUsers: 'dailyInactiveUserDrifts',
  },
  saasData: 'saasData',
  ghosts: 'ghosts',
  ghostsCount: 'ghostsCount',
  zombies: 'zombies',
  zombiesCount: 'zombiesCount',
  externalUsers: 'externalUsers',
  inactiveUsers: 'inactiveUsers',
  inactiveUsersCount: 'inactiveUsersCount',
  userData: 'userData',
  allGenericUsers: 'allGenericUsers',
  genericUsersOmitNullNames: 'genericUsersOmitNullNames',
  clients: 'clients',
  portalUsers: 'portalUsers',
  privilegeDrifts: 'privilegeDrifts',
  privilegeSummary: 'privilegeSummary',
  userAccessExceptions: 'userAccessExceptions',
  authExceptionsSummary: 'authExceptionsSummary',
  authExceptions: 'authExceptions',
  mfaDisabled: 'mfaDisabled',
  mfaDisabledCount: 'mfaDisabledCount',
  allEnabledUsersCount: 'allEnabledUsersCount',
  newAppsInOrg: 'newAppsInOrg',
  newAppsInOrgCount: 'newAppsInOrgCount',
  newUserAppsCount: 'newUserAppsCount',
  newUserApps: 'newUserApps',
  drifts: 'drifts',
  appDrifts: 'appDrifts',
  userAppDrifts: 'userAppDrifts',
  dataSharings: 'dataSharings',
  addSaaSGuides: 'addSaaSGuides',
  oauthRedirect: 'oauthRedirect',
  activityLog: 'activityLog',
  externalForwards: 'externalForwards',
  internalForwards: 'internalForwards',
  externalShares: 'externalShares',
  dlp:'dlp',
  stripeProducts: 'stripeProducts',
  loggedInUser:'loggedInUser',
}

export const cacheKeyForSaasData = (
  {
    saasIdentifier,
    connectionId,
    shouldOmitExternalMfaDisabled
  } : {
    saasIdentifier?: string | undefined;
    connectionId?: string | undefined;
    shouldOmitExternalMfaDisabled?: boolean | undefined;
  }
) => [
  cacheKeys.saasData,
  saasIdentifier,
  shouldOmitExternalMfaDisabled,
  connectionId
];

export const cacheKeyForDiscoveryAppInfo = (userPubId: string | undefined) =>
  `${cacheKeys.discovery.appInfo}-${userPubId}`;

export const cacheKeyForAppDrifts = (appPubId: string | undefined) =>
  `${cacheKeys.appDrifts}-${appPubId}`;

export const cacheKeyForUserAppDrifts = (userPubId: string | undefined) =>
  `${cacheKeys.userAppDrifts}-${userPubId}`;

export const cacheKeyForUserExternalShares = (userPubId: string | undefined) =>
  `${cacheKeys.externalShares}-${userPubId}`;

export const cacheKeyForUserDlp = (userPubId: string | undefined) =>
  `${cacheKeys.dlp}-${userPubId}`;

export const cacheKeyForUserExternalMailForwards = (userPubId: string | undefined) =>
  `${cacheKeys.externalForwards}-${userPubId}`;


export const cacheKeyForDiscoveryUsersForApp = (userPubId: string | undefined) =>
  `${cacheKeys.discovery.usersForApp}-${userPubId}`;

export const cacheKeyForDiscoveryAppsForUser = (userPubId: string | undefined) =>
  `${cacheKeys.discovery.usersForApp}-${userPubId}`;

export const cacheKeyForDiscoveryAppsForGenericUser = (
  genericUserPubId: string | undefined
) => `${cacheKeys.discovery.appsForGenericUser}-${genericUserPubId}`;

export const cacheKeyForUserData = (userId: string | undefined) =>
  `${cacheKeys.userData}-${userId}`;

export const cacheKeyForGhosts = ({
  saasIdentifier,
  connectionId,
  privilegeCode,
}:{
  saasIdentifier?: string|undefined,
  connectionId?: string|undefined,
  privilegeCode?: string|undefined|null
}) => [
  cacheKeys.ghosts,
  saasIdentifier,
  connectionId,
  privilegeCode
];

export const cacheKeyForExternalUsers = ({
  saasIdentifier,
  privilegeCode,
}:{
  saasIdentifier?: string|undefined,
  privilegeCode?: string|null,
}) => `${cacheKeys.externalUsers}-${saasIdentifier}-${privilegeCode}`;

export const cacheKeyForInactiveUsers = ({
  saasIdentifier,
  connectionId,
  privilegeCode,
  shouldIncludeInternal,
  shouldIncludeExternal,
}:{
  saasIdentifier?: string|undefined,
  connectionId?: string|undefined,
  privilegeCode?: string|null,
  shouldIncludeInternal?: boolean,
  shouldIncludeExternal?: boolean,
}) => [
  cacheKeys.inactiveUsers,
  saasIdentifier,
  connectionId,
  privilegeCode,
  shouldIncludeInternal,
  shouldIncludeExternal,
];

export const cacheKeyForMfaDisabled = ({
  saasIdentifier,
  connectionId,
  privilegeCode,
  shouldIncludeExternal,
  shouldIncludeInternal,
}:{
  saasIdentifier?: string, // saas identifier or generic user pubId
  connectionId?: string|undefined,
  privilegeCode?: string,
  shouldIncludeExternal?: boolean,
  shouldIncludeInternal?: boolean
}) => [
  cacheKeys.mfaDisabled,
  saasIdentifier,
  connectionId,
  privilegeCode,
  shouldIncludeInternal,
  shouldIncludeExternal,
];

export const userDomainStrings = {
  internal: 'Internal',
  external: 'External',
}
