import {useCallback} from 'react';
import {useParams} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {
  useComponentForQueryResult
} from 'src/hooks/UseComponentForQueryResult';
import {cacheKeys} from 'src/services/shared/serviceConstants';
import {searchParamKeys} from 'src/routes';
import {privilegeStringFromCode} from 'src/utils/constants';
import {AuthExceptionTable} from './AuthExceptionTables/AuthExceptionTable';
import {getMfaDisabled} from 'src/services/shared/MFADisabled';
import {useLegacyQueryWithToken} from "src/hooks/TanstackHooks";
import {plural} from "src/utils/string";

export const AuthExceptionsPage = () => {
  const documentTitle = 'Authentication Exceptions - Detexian';
  document.title = documentTitle;

  const {saasIdentifier} = useParams<{ saasIdentifier?: string }>();
  const [searchParams] = useSearchParams();

  const privilegeLevelCode = searchParams.get(searchParamKeys.privilege)
  const privilegeLevel = privilegeStringFromCode(privilegeLevelCode);

  const queryResult = useLegacyQueryWithToken(
    [`${cacheKeys.authExceptions}+${saasIdentifier}+${privilegeLevelCode}`],
    getMfaDisabled,
    {
      saasIdentifier,
      privilegeLevel,
    }
  );

  const pageLoadFunction = useCallback(
    () => {
      const count = queryResult.data.length;
      console.log({queryResult: queryResult.data})
      const heading = `${count} ${privilegeLevel}${plural(count)} with Disabled MFA`;
      console.log({heading});
      return (
        <AuthExceptionTable
          users={queryResult.data}
          saasIdentifier={saasIdentifier}
          heading={heading}
        />
      );
    },
    [privilegeLevel, queryResult.data, saasIdentifier]
 );

  return useComponentForQueryResult({
    queryResult,
    pageLoadFunction,
    userErrorMessage: 'error accessing authentication exceptions',
  });
};
