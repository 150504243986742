import {gql} from 'graphql-request';
import {saasFragment, userCoreFragment} from 'src/services/shared/Fragments';
import {userDomainStrings} from 'src/services/shared/serviceConstants';
import {userStatusStrings} from 'src/utils/constants';


export const userAccessSummaryQuery = gql`
query UserAccessSummary(
  $sot_pub_id: uuid
) {
  client_saas(where: {saas: {}}) {
      privilege_levels {
        privilege_level
        external_user
        external_user_rating
        inactive_user
        inactive_user_rating
        active_inactive_user(args:{sot_pub_id:$sot_pub_id})
        active_inactive_user_rating(args:{sot_pub_id:$sot_pub_id})
      }
    saas {
      ...Saas
      # can remove this one
      privilege_levels {
        privilege_level
        external_user
        external_user_rating
        inactive_user
        inactive_user_rating
        active_inactive_user(args:{sot_pub_id:$sot_pub_id})
        active_inactive_user_rating(args:{sot_pub_id:$sot_pub_id})
      }
    }
    pub_id # the connection id
    nick_name
  }
}
${saasFragment}
`;

export const externalUserQuery = gql`
query ExternalUsers(
  $saasIdentifier: String
  $privilegeLevel: privilege_level
) {
  saas(
    where: {
      name: {_eq: $saasIdentifier}
    }
  ) {
    latest_saas_users(
      where: {
        domain: {_neq: ${userDomainStrings.internal}}, 
        status: {_eq: ${userStatusStrings.enabled}},
        privilege_level: {_eq: $privilegeLevel},
      }, 
      # limit: 10, 
      order_by: {privilege_level: asc}
    ) {
      saas {...Saas}
      pub_id
#      name
#      email
#      privilege_level
#      mfa_status
#      last_login
      ...UserCore
      
      generic_user {
        pub_id
      }
    }
  }
}
${saasFragment}
${userCoreFragment}
`;

