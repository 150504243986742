import {useEffect, useState} from 'react';
import {useFilterMenu} from 'src/pages/ActivityLogPage/FilterMenu';
import {getConnectedSaases} from 'src/services/SaasService/Saases';
import {cacheKeys} from 'src/services/shared/serviceConstants';
import {ISaas} from 'src/types/Saas';
import {useLegacyQueryWithToken} from "src/hooks/TanstackHooks";

export const saasFilterDefaultLabel = 'All SaaSes';

// we define this here so we can set the
// default selectionState to All SaaSes
const saasFilterDefaultOptions = [{
  id: 'allSaas',
  value: '',
  label: saasFilterDefaultLabel
}];

export const useSaasFilterMenu = () => {
  const {data: connectedSaases} = useLegacyQueryWithToken(
    [cacheKeys.connectedSaasList],
    getConnectedSaases,
  )

  const saasFilterMenuOptionsState = useState(saasFilterDefaultOptions);

  const [
    saasFilterMenuOptions,
    setSaasFilterMenuOptions
  ] = saasFilterMenuOptionsState;

  useEffect(
    () => {
      if (connectedSaases) {
        let options = saasFilterDefaultOptions; // kick off with just All SaaS

        // transform to format amenable to MUI MenuItem
        const transformedSaases = connectedSaases.map(
          (aSaas: ISaas) => ({
            id: aSaas.identifier,
            value: aSaas.identifier,
            label: aSaas.name
          })
        )
        options = options.concat([...transformedSaases])
        // console.log('useEffect', {options})
        setSaasFilterMenuOptions(options);
      }
    },
    [connectedSaases, setSaasFilterMenuOptions]
  )

  const filterMenu = useFilterMenu({
    label: 'SaaS',
    options: saasFilterMenuOptions,
    ariaLabel: 'Filter for SaaS',
    menuId: 'saasFilterMenuId',
  });

  return {
    FilterMenu: () => (
      <SaasFilterMenu
        filterMenu = {filterMenu}
      />
    ),
    selectionState: filterMenu.selectionState,
  };
}

const SaasFilterMenu = ({filterMenu}: {filterMenu: any}) => {
  const FilterMenuComponent = filterMenu.filterMenu;
  return <FilterMenuComponent />
};
