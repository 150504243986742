import {FC, useCallback, useState} from 'react';
import {useParams} from 'react-router';
import {useComponentForQueryResult} from 'src/hooks/UseComponentForQueryResult';
import {getConnectedSaases} from 'src/services/SaasService/Saases';
import {getGhosts} from 'src/services/shared/Ghosts';
import {getSaasDetails} from 'src/services/SaasService/SaasView';
import {getInactiveUsers} from 'src/services/shared/InactiveUsers';
import {getMfaDisabled} from 'src/services/shared/MFADisabled';
import {SingleSaasComponent} from './SingleSaasComponent';
import {
  cacheKeyForGhosts, cacheKeyForInactiveUsers, cacheKeyForMfaDisabled,
  cacheKeyForSaasData, cacheKeys,
} from 'src/services/shared/serviceConstants';
import {connectedSaasForConnectionId} from "src/utils/saasUtils";
import {useLegacyQueryWithToken, useQueryWithAccessToken} from "src/hooks/TanstackHooks";
import {useClientInfo} from "../../hooks/ClientHooks";

// handles data aquisition and decides whether to
// return the page, loading or error component
export const SingleSaasPage: FC = () => {
  const {saasIdentifier, connectionId} = useParams<
    {
      saasIdentifier: string | undefined;
      connectionId: string | undefined;
    }
  >();

  const [userErrorMessage, setUserErrorMessge] = useState('');

  // console.log({saasIdentifier}, {connectionId});

  // These three queries are chained in a way
  // The last will only be enabled when the other
  // two have received data.  This allows a simple
  // implementation of useComponentForQueryResult
  // but may result in errors in the first two going
  // undetected.
  const clientInfoResponse = useClientInfo();

  const {data: connectedSaases} = useLegacyQueryWithToken(
    [cacheKeys.connectedSaasList],
    getConnectedSaases,
    {},
    {
      enabled: !!clientInfoResponse.data,
      onError: () => {
        setUserErrorMessge('error accessing source of truth');
      }
    }
  )

  // TODO passing both, redundant, should only need connectionId
  const sharedQueryVariables = {
      saasIdentifier,
      connectionId,
      sot : clientInfoResponse.data?.sourceOfTruth
  };

  const ghostQueryResult = useLegacyQueryWithToken(
    cacheKeyForGhosts(sharedQueryVariables),
    getGhosts,
    sharedQueryVariables,
    {
      enabled: !!connectedSaases,
      onError: () => {
        setUserErrorMessge('error accessing ghosts');
      }
    }
  );

  // this is here so SingleSaasComponent has a cached
  // result for its own query
  const mfaDisabledResult = useLegacyQueryWithToken(
    cacheKeyForMfaDisabled(sharedQueryVariables),
    getMfaDisabled,
    sharedQueryVariables,
    {
      onError: () => {
        setUserErrorMessge('error accessing mfa disabled');
      }
    }
  );

  // this is here so SingleSaasComponent has a cached
  // result for its own query
  const inactiveQueryResult = useLegacyQueryWithToken(
    cacheKeyForInactiveUsers(sharedQueryVariables),
    getInactiveUsers, // getInactiveUsersForAccessExceptions,
    sharedQueryVariables,
    {
      enabled: !!mfaDisabledResult.data,
      onError: () => {
        setUserErrorMessge('error accessing ghosts');
      }
    }
  );

  // const saasQueryResult = useLegacyQueryWithToken(
  //   cacheKeyForSaasData(sharedQueryVariables),
  //   getSaasDetails,
  //   sharedQueryVariables,
  //   {enabled: !!inactiveQueryResult.data}
  // );

  // console.log(
  //   {saasIdentifier},
  //   {connectionId},
  //   'enabled: ', !!inactiveQueryResult.data
  // );
  const saasQueryResult = useQueryWithAccessToken({
    queryKey: cacheKeyForSaasData(sharedQueryVariables),
    queryFunction: getSaasDetails,
    queryFunctionVariables: sharedQueryVariables,
    tanstackOptions: {
      enabled: !!inactiveQueryResult.data && !!clientInfoResponse.data,
      onError: () => {
        setUserErrorMessge('error accessing saas details');
      }
    }
  });

 // builds the successful page for the hook below
  const pageLoadFunction = useCallback(
    () => {
      const connectedSaas = connectedSaasForConnectionId({
        identifier: saasIdentifier,
        connectionId: connectionId,
        connectedSaases: connectedSaases
      })

      return (
        <SingleSaasComponent
          saasData={saasQueryResult.data}
          connectedSaas={connectedSaas}

          // not strictly needed right now
          connectedSaases={connectedSaases}

          connectionId={connectionId}
          ghosts={ghostQueryResult.data}
          firstLoadMfaDisabled={mfaDisabledResult.data}
          firstLoadInactive={inactiveQueryResult.data}
          sot={clientInfoResponse.data?.sourceOfTruth}
        />
      );
    },
    [connectedSaases, connectionId, ghostQueryResult.data, inactiveQueryResult.data, mfaDisabledResult.data, saasIdentifier, saasQueryResult.data, clientInfoResponse.data]
  );

  return useComponentForQueryResult({
    queryResult: saasQueryResult,
    pageLoadFunction,
    userErrorMessage,
    errorMessageDoesIndicateError: true
  })
};
