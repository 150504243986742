import {useParams} from "react-router";
import {Box, Typography as T, useTheme} from "@mui/material";
import {getAllSaas} from 'src/services/SaasService/Saases';
// import logoImage from "src/assets/images/other/logoWithName.png";
import {SaasLogoContainer} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/misc/styles";
import {SaasLogo} from "src/components/SaasLogo";
import {cacheKeys} from "src/services/shared/serviceConstants";
import {DtxSpacer} from "src/components/common/DtxSpacer";
import {useLegacyQueryWithToken} from "src/hooks/TanstackHooks";
import {logoWithNamePath} from "src/utils/constants";

const saasNameFromId = (
  saasIdentifier: string | undefined,
  allSaaSes: any[]
) => {
  const theSaas = allSaaSes.find((saas) => saas.identifier === saasIdentifier);
  return theSaas?.name;
};


// only used for local guides - not for Contentful guides
export const GuideExample = () => {
  const {data: allSaases} =
    useLegacyQueryWithToken(
      [cacheKeys.allSaasList],
      getAllSaas
    );
  const theme = useTheme();
  const {saasIdentifier} = useParams<{saasIdentifier: string}>();
  const saasName = !allSaases ? '' : saasNameFromId(
    saasIdentifier,
    allSaases
  )
  return (
    <Box sx={{
      // height: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      margin: "auto"
    }}>
      <Box sx={{
        height: '500px',
        width: "50%",
        maxWidth: "600px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        margin: "auto",
        border: "1px solid gray",
        borderRadius: theme.more.borderRadius,
        padding: "5%"
      }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <img
            src={logoWithNamePath}
            height="45px"
            width="150px"
            alt="Logo"
            // style={{marginBottom:'12px', marginTop: '-12px'}}
          />
          <SaasLogoContainer  width={50}>
            {saasIdentifier ?
              <SaasLogo saasIdentifier={saasIdentifier} />
              :
              "error:"
            }
          </SaasLogoContainer>
        </Box>
        <DtxSpacer />
        <br />
        <T>To give Detexian access your {saasName} account, we will take you through these
          steps:</T>
        <ul>
          <li>Agree to Detexian's privacy policy.</li>
          <li>Review a clear description of the data we will access.</li>
          <li>Agree to proceed or cancel the process.</li>
          <li>Sign in to your Detexian account.</li>
          <li>Switch accounts if required.</li>
        </ul>
      </Box>
    </Box>
  );
};
