import {useLegacyQueryWithToken} from "../../../hooks/TanstackHooks";
import {cacheKeys} from "../../../services/shared/serviceConstants";
import {UserConfigTable} from "../../../components/common/Tables/UserConfigTable";
import React from "react";
import {usePortalContext} from "../../../hooks/PortalContext";
import {getGhosts} from "../../../services/shared/Ghosts";
import {Box} from "@mui/material";
import {getGridHeadingCount} from "../../../components/common/Tables/Utilities";

export interface GhostUserPanelProps {
  sot: any;
}
export const GhostUsersPanel = (props: GhostUserPanelProps) => {
  const {sot} = props;
  const {clientInfo} = usePortalContext();
  const {isLoading, data} = useLegacyQueryWithToken(
    [cacheKeys.ghosts],
    getGhosts,
    {sot},
    {
      enabled: clientInfo?.configuration?.features?.ghost && !!sot  // Only query if the feature is on and there is a SoT
    }
  );

  return <>
    {
        <UserConfigTable
          heading={getGridHeadingCount(data, 'Ghost Users')}
          users={data}
          shouldShowSotColumn={true}
          shouldShowSotLastUpdatedColumn={true}
          shouldShowActionColumn={true}
          isLoading={isLoading}
          tableDescription={<Box sx={{lineHeight: '1.4rem'}}>Ghost users are inferred from accounts with similar user details across different SaaSes. They are often accounts belonging to ex-employees and former consultants whose access hadn't been fully revoked.<br />
            Users are matched between different SaaSes on registered email address and full names. As a result, false-positives are possible.</Box>}
        />
    }
  </>;
}