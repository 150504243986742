import React, {useCallback, useState} from 'react';

import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {
  CustomFooter,
  DefaultGrid,
  GridHeadingWithExport,
} from "../../../../components/common/Tables/DefaultGrid";
import {Box} from "@mui/material";
import {ICompanyDomain} from "../../../../types/CompanyDomain";
import {useRemoveDomainConfirmationDialog} from "./useRemoveDomainConfirmationDialog";
import {useMutationWithAccessToken} from "../../../../hooks/TanstackHooks";
import {addCompanyDomain, deleteCompanyDomain} from "../../../../services/Domains/DomainQueries";
import {useManageDomainErrorDialog} from "./useManageDomainErrorDialog";
import {useQueryClient} from "@tanstack/react-query";
import {cacheKeys} from "../../../../services/shared/serviceConstants";
import {DefaultButton} from "../../../../components/common/DefaultButton";
import {h5FontSize, theme} from "../../../../theme";
import {AddDomain} from "./AddDomain";
import {Pill} from "../../../../components/common/PIll";


export const DomainsComponent = ({
    companyDomains
}: {
  companyDomains: ICompanyDomain[],
}) => {
  const minRowsPerPageOption = 100 // largest allowed in free licence
  const rowsPerPageOptions = [minRowsPerPageOption]

    const queryClient = useQueryClient();

    const [selectedDomain, setSelectedDomain] = useState<ICompanyDomain>(
        {
            id: '',
            name: '',
            registered: false,
        }
    );

  // State required for domain validation
  const [addDomainValidationOpen, setAddDomainValidationOpen] = useState(false);
  const [addDomainValidationMessage, setAddDomainValidationMessage] = useState('');

  // State required for removing domains
  const [isRemovePending, setIsRemovePending] = useState(false);

  const [isAddDomainPending, setIsAddDomainPending] = useState(false);

    // Construct the error dialog.  Used for both removing and adding domains
    const {
        dialog: errorDialog,
        setIsOpen: setIsErrorDialogOpen,
        setErrorMessage,
    } = useManageDomainErrorDialog({
        onDismiss: ()=>{},
    })

    const {
        // isLoading, isSuccess, error,
        mutate: doRemoveDomain,
    } = useMutationWithAccessToken({
        mutation: deleteCompanyDomain,
        tanstackOptions: {
            // temporarily hide the removed connection -
            // see filteredRows, above
            onSuccess: () => {
                console.log('doRemoveDomain - onSuccess');
                // Invalidating the cache will cause the domains to get re-queried and updated accordingly
                queryClient.invalidateQueries([cacheKeys.companyDomains]);
                setIsRemoveConfirmationDialogOpen(false);
                setIsRemovePending(false);
            },
            onError: (error: any) => {
                console.log('doRemoveDomain - onError');
                setIsRemoveConfirmationDialogOpen(false);
                setIsRemovePending(false);
                setIsErrorDialogOpen(true);
                setErrorMessage('Could not remove domain');
            }
        }
    });
    const onConfirmRemoveDomain = () => {
        setIsRemovePending(true);
        // @ts-ignore
        doRemoveDomain({domain: selectedDomain});
    }

    const {
        // isLoading, isSuccess, error,
        mutate: doAddDomain,
    } = useMutationWithAccessToken({
        mutation: addCompanyDomain,
        tanstackOptions: {
            // temporarily hide the removed connection -
            // see filteredRows, above
            onSuccess: () => {
                console.log('doAddDomain - onSuccess');
                // Invalidating the cache will cause the domains to get re-queried and updated accordingly
                setAddDomainValidationOpen(false);
                setAddDomainValidationMessage('');
                queryClient.invalidateQueries([cacheKeys.companyDomains]);
                setIsAddDomainPending(false);
            },
            onError: (error: any) => {
                console.log('doAddDomain - onError');
                setAddDomainValidationOpen(true);
                setAddDomainValidationMessage('There was an error adding domain');
                setIsAddDomainPending(false);
            }
        }
    });

    const onAddDomain = (name: string) => {
        // Do client side validation
        // Make sure a domain name is entered
        if(name === ''){
            setAddDomainValidationOpen(true);
            setAddDomainValidationMessage("Enter a domain name");
            return;
        }

        // Make sure it doesn't already exist
        if(companyDomains.some((d) => {return d.name.toLowerCase() === name.toLowerCase()}))
        {
            setAddDomainValidationOpen(true);
            setAddDomainValidationMessage(`Domain (${name}) is already registered`);
            return;
        }

        setIsAddDomainPending(true);
        // Client side validation is done, so do the server call
        // @ts-ignore
        doAddDomain({domainName: name});
    }

    const {
        dialog: removeConfirmationDialog,
        setIsOpen: setIsRemoveConfirmationDialogOpen,
    } = useRemoveDomainConfirmationDialog({
        domain: selectedDomain,
        isSpinning: isRemovePending,
        onCancel: ()=>{},
        onConfirm: onConfirmRemoveDomain,
    })


  const columns = useCallback(
      (): GridColDef [] => {

      const handleRemoveClick = (domain: ICompanyDomain) => {
          setSelectedDomain(domain);
          setIsRemoveConfirmationDialogOpen(true);
      }

        return [
          {
            headerName: 'Domain name',
            field: 'name',
            minWidth: 200,
            flex: 3,
              sortable: true,

          },
          {
            headerName: 'Type',
            field: 'registered',
              renderCell:(params: GridRenderCellParams) => {
                let pillColor = params.row.registered ? theme.more.andyBlue : theme.more.andyGreen;
                  let pillTitle = params.row.registered ? "Registered" : "Discovered";
                return (<Pill
                    pillWidth="82px"
                    pillColor={pillColor}
                    title={pillTitle}>
                </Pill>)
              },
            // minWidth: 200,
            flex: 1,
              sortable: true,
          },
            {
                headerName: '',
                field: 'action',
                renderCell: (params: GridRenderCellParams) => {
                        return params.row.registered && (<DefaultButton onClick={() => handleRemoveClick(params.row)}>
                            Remove
                        </DefaultButton>)
                },
                sortable: false,
                disableExport: true,
                disableReorder: true,
                filterable: false,
                disableColumnMenu: true,
                flex: 0.5,
                align: "right",
            },
        ];
      },
      [setIsRemoveConfirmationDialogOpen]
  );

  const [paginationModel, setPaginationModel] = useState({
    pageSize: minRowsPerPageOption,
    page: 0,
  });

  return (<>
      {removeConfirmationDialog}
      {errorDialog}

      <DefaultGrid
          rows={companyDomains}
          getRowId={row => row.id}
          columns={columns()}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={rowsPerPageOptions}
          length={companyDomains?.length}
          minRowsPerPageOption={minRowsPerPageOption}
          toolbar={() =>
              <GridHeadingWithExport
                  heading="Internal Domains"
                  shouldShowExportButton={false}
                  shouldShowBackToPrevious={false}
                  tableDescription={<><p>Internal domains are used to determine if a user is part of your organization.</p>
                      <p>Domains marked as "Discovered" have been automatically detected from Microsoft 365 and cannot be removed.<br />
                      Domains marked as "Registered" can be added and removed through this page.</p></>
                    }
              />}
          footer={() => CustomFooter({})}
      />

        <Box
            sx={{
                margin: '20px 0px 5px 8px',
                fontSize: `${h5FontSize}`,
                fontWeight: 'bold',
            }}
        >
            Add registered domain
        </Box>
        <AddDomain validationMessage={addDomainValidationMessage} showValidation={addDomainValidationOpen} onAddDomain={onAddDomain} isSpinning={isAddDomainPending}  />
  </>)
};
