import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {CaptureConsole} from '@sentry/integrations';
import ReactGA from "react-ga4";
import React from 'react';
import ReactDOM from 'react-dom';
import {isLocalhost} from 'src/utils/other';
import {App} from './App';

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
const instanceTag = process.env.REACT_APP_INSTANCE_TAG;

const gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

// console.log({sentryDsn}, {environmentTag})
if (sentryDsn && instanceTag) {
  // console.log('starting sentry for', environmentTag);
  try {
    Sentry.init({
      dsn:sentryDsn,
      integrations: [
        new BrowserTracing(),
        // @ts-ignore
        new CaptureConsole({
          levels: ['error']
        }),
      ],
      environment: instanceTag,
      // We recommend adjusting this value in production,
      // or using tracesSampler for finer control
      tracesSampleRate: 1.0,
    });
  }
  catch (e) {
    console.log('sentry failure', {e});
  }
}
else {
  if (!isLocalhost()) console.log(
    'Not starting Sentry - missing Sentry environment vars: ' +
    'REACT_APP_SENTRY_DSN and/or REACT_APP_SENTRY_ENVIRONMENT_TAG'
  );
}

// If there's a measurement id then initialise the GA client
if (gaMeasurementId)
{
  ReactGA.initialize(gaMeasurementId);
}


// console.error('testing sentry console.error capture');

// const hjid = process.env.REACT_APP_HOTJAR_HJID;
// const hjsv = process.env.REACT_APP_HOTJAR_HJSV;
//
// if (hjid && hjsv) hotjar.initialize(parseInt(hjid, 10), parseInt(hjsv, 10));

const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

const initializeBeforeAppRender = async () => {
  // this doesn't work - it's too soon, no authentication yet
  // await graphQLClient(); // get an Auth0 ID Token cached

  renderApp()
}

initializeBeforeAppRender();

export {useAuth0Token} from 'src/hooks/Auth0Hooks';
export {useComponentForQueryResult} from 'src/hooks/UseComponentForQueryResult';
export {DtxSpacer} from 'src/components/common/DtxSpacer';
export {useWidthForRef} from 'src/hooks/useWidthForRef';
export {isSaasConnected} from "src/utils/saasUtils";
export {useLegacyQueryWithToken} from "src/hooks/TanstackHooks";
