import {useTheme} from '@mui/material';
import Grid from '@mui/material/Grid';
import React, {FC, useEffect, useMemo, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {HtmlTooltip} from 'src/components/common/HtmlTooltip';
import {UserActionTable} from 'src/components/common/Tables';
import {UserConfigTable} from 'src/components/common/Tables/UserConfigTable';
import {useDomainFilter} from 'src/components/common/Tables/DefaultGrid/DomainFilter';
import {riskTypeIdentifiers, riskTypeToDisplay, singlePanelUrl} from 'src/routes';
import {getInactiveUsers} from 'src/services/shared/InactiveUsers';
import {getMfaDisabled} from 'src/services/shared/MFADisabled';
import {
  cacheKeyForInactiveUsers,
  cacheKeyForMfaDisabled, userDomainStrings,
} from 'src/services/shared/serviceConstants';
import {CountSummaryHeader} from './CountSummaryHeader';
import {compactInfoFontSize} from "src/theme";
import {ConnectedSaas} from "src/types/Saas";
import {useLegacyQueryWithToken} from "src/hooks/TanstackHooks";

export const DLPExportFilenameFragment = (
  dlps: any
) => {
  return `${dlps?.length} DLPs`;
}

export const DLPHeading = ({
  dlpCount,
  // GridHeadingWithExport uses exportNameFragment to create an
  // export filename for DLPs because the DLP heading confuses it.
  // And, users of this component have to actually pass in as a prop
  // or the hack in GridHeadingWithExport doesn't see it the prop.
  exportNameFragment,
}: {
  dlpCount: number;
  exportNameFragment: string;
}) => {
  const theme = useTheme();
  return (
    <span>
      Data Loss Prevention&nbsp;
      <em
        style={{
          color: theme.palette.text.secondary,
          fontSize: '1.2rem',
        }}
      >
        DLP
      </em>
      &nbsp;&nbsp;({dlpCount || 0})
    </span>
  );
}

type props = {
  saasData: any;
  connectedSaas: ConnectedSaas; // for the summary header SaasNameAndLogo

  // this is passed to all the grids even though they don't
  // currently need it since they don't display SaasNameAndLogo
  // components like other grids do e.g. User View
  connectedSaases: ConnectedSaas[];

  connectionId: string | undefined;
  ghosts: any;
  firstLoadMfaDisabled: any;
  firstLoadInactive: any;
  sot: any;
}

export const SingleSaasComponent: FC<props> = ({
  saasData,
  connectedSaas,
  connectedSaases,
  connectionId,
  ghosts,
  firstLoadMfaDisabled,
  firstLoadInactive,
  sot,
}) => {
  const [searchParams] = useSearchParams();

  const saas = saasData?.saas;
  const HPUs = saasData?.highlyPrivilegedUsers;
  const PUs = saasData?.privilegedUsers;
  const Us = saasData?.enabledUsers;
  const allUsers = saasData?.allUsers;
  //const permissionStatus = HPUs?.concat(PUs);
  const external = saasData?.externalUsers;
  // const noOfMfa = saasData?.noOfMfa
  // const inactive = saasData?.inactive60DaysUsers;
  const forwards = saasData?.externalMailForwards;
  const shares = saasData?.externalShares;
  const risks = saasData?.riskPolicyEvents;

  // console.log(
  //   'SingleSaasComponent rendering',
  //   {saas},
  //   {mfaDisabled},
  //   {forwards},
  //   {risks},
  //   {shares},
  //   {ghosts},
  // );

  const saasIdentifier = saas.identifier

  const [isLoadingMfa, setIsLoadingMfa] = useState(false);
  const [
    isLoadingInactive,
    setIsLoadingInactive
  ] = useState(false);
  const [isLoadingAll, setIsLoadingAll] = useState(false);

  // Domain Filters

  const {
    shouldIncludeInternal: shouldIncludeInternalMfaDisabled,
    shouldIncludeExternal: shouldIncludeExternalMfaDisabled,
    DomainFilter: MFADomainFilter,
  } = useDomainFilter(isLoadingMfa);

  const {
    shouldIncludeInternal: shouldIncludeInternalInactive,
    shouldIncludeExternal: shouldIncludeExternalInactive,
    DomainFilter: InactiveDomainFilter,
  } = useDomainFilter(isLoadingInactive);

  const {
    shouldIncludeInternal: shouldIncludeInternalAllUsers,
    shouldIncludeExternal: shouldIncludeExternalAllUsers,
    DomainFilter: AllUsersDomainFilter,
  } = useDomainFilter(isLoadingAll);

  // Queries

  const mfaDisabledResult = useLegacyQueryWithToken(
    [
      cacheKeyForMfaDisabled(
        {
          saasIdentifier,
          connectionId,
          shouldIncludeInternal: shouldIncludeInternalMfaDisabled,
          shouldIncludeExternal: shouldIncludeExternalMfaDisabled,
        }
      )
    ],
    getMfaDisabled,
    {
      saasIdentifier,
      connectionId,
      shouldIncludeInternal: shouldIncludeInternalMfaDisabled,
      shouldIncludeExternal: shouldIncludeExternalMfaDisabled,
    }
  );

  const inactiveQueryResult = useLegacyQueryWithToken(
    cacheKeyForInactiveUsers({
      saasIdentifier,
      connectionId,
      shouldIncludeInternal: shouldIncludeInternalInactive,
      shouldIncludeExternal: shouldIncludeExternalInactive,
    }),
    getInactiveUsers, // getInactiveUsersForAccessExceptions,
    {
      saasIdentifier,
      connectionId,
      shouldIncludeInternal: shouldIncludeInternalInactive,
      shouldIncludeExternal: shouldIncludeExternalInactive,
    },
  );

  useEffect(() => {
    setIsLoadingMfa(mfaDisabledResult.isLoading);
    setIsLoadingInactive(inactiveQueryResult.isLoading);
    setIsLoadingAll(false); // TODO fix
  }, [inactiveQueryResult.isLoading, mfaDisabledResult.isLoading])

  // to smooth loading of the filtered panels
  const mfaDisabled = mfaDisabledResult.data || firstLoadMfaDisabled;
  const inactive = inactiveQueryResult.data || firstLoadInactive;

  // Page title defined here, rather than in the page, because
  // we don't have saasData there, on page load
  const pageTitle = `SaaS - Detexian - ${saasData?.saas.name}`;
  document.title = pageTitle;

  // All Users pose-query filtering

  const allUsersFilteredByDomain = useMemo(
    () => {
      // This line probably won't make the loading indicator appear
      // because the filter function takes over the main thread.
      // But on a modern computer, its so quick it mightn't matter
      setIsLoadingAll(true);

      const result = allUsers?.filter(
        (user: { domain: string; }) => {
          let result = true;
          if (
            shouldIncludeInternalAllUsers &&
            !shouldIncludeExternalAllUsers
          )
            result = user.domain === userDomainStrings.internal;
          else if (
            !shouldIncludeInternalAllUsers &&
            shouldIncludeExternalAllUsers
          )
            result = user.domain === userDomainStrings.external;
          // don't need to implement the both false case for now - the UI prevents it

          return result;
        }
      )
      setIsLoadingAll(false);
      return result;
    }, [allUsers, shouldIncludeExternalAllUsers, shouldIncludeInternalAllUsers]
  )

  // Panels

  const allUserPanel = () => {
    return <>
      {
        allUsersFilteredByDomain?.length > 0 &&
        <Grid item xs={12}>
          <UserConfigTable
            id="privileged-users-table"
            users={allUsersFilteredByDomain}
            heading={
              `Users (${allUsersFilteredByDomain.length})`
            }
            saas={saas}
            shouldHideSaasLogoInHeading={true}
            shouldShowSotColumn={false}
            shouldShowMfaColumn={true}
            singlePanelUrl={
              singlePanelUrl(
                searchParams,
                riskTypeIdentifiers.allUsers,
                allUsersFilteredByDomain?.length
              )
            }
            shouldSortByPrivilege={true}
            DomainFilter={AllUsersDomainFilter}
          />
        </Grid>
      }
    </>;
  }

  const mfaDisabledPanel = () => {
    return <>
      {
        mfaDisabled?.length > 0 &&
        <Grid item xs={12}>
          <UserConfigTable
            id="mfa-disabled-table" // used for automated testing
            users={mfaDisabled}
            heading={
              `Disabled MFA (${mfaDisabled.length})`
            }
            saas={saas}
            shouldHideSaasLogoInHeading={true}
            shouldShowSotColumn={false}
            shouldShowMfaColumn={true}
            singlePanelUrl={
              singlePanelUrl(
                searchParams,
                riskTypeIdentifiers.mfaDisabled,
                mfaDisabled?.length
              )
            }
            shouldSortByPrivilege={true}
            DomainFilter={MFADomainFilter}
          />
        </Grid>
      }
    </>;
  }

  const ghostPanel = () => {
    return <>
      {
        ghosts?.length > 0 && (
          <Grid item xs={12}>
            <UserConfigTable
              id="ghost-table" // used for automated testing
              heading={
               <HtmlTooltip
                  title={
                    <React.Fragment>
                        <span
                          style={{
                            fontSize: compactInfoFontSize,
                          }}
                        >
                          Ghosts Users don't have an active account in your source of truth{sot ? `, ${sot.name}` : ''}
                        </span>
                    </React.Fragment>
                  }
                >
                 <span>{`Ghost Users (${ghosts.length})`}</span>
               </HtmlTooltip>
              }
              saas={saas}
              shouldHideSaasLogoInHeading={true}
              shouldShowSotColumn={true}
              shouldShowSotLastUpdatedColumn={true}
              shouldShowActionColumn={true}
              users={ghosts}
              singlePanelUrl={
                singlePanelUrl(
                  searchParams,
                  riskTypeIdentifiers.ghosts,
                  ghosts?.length
                )
              }
            />
          </Grid>
        )
      }
    </>;
  }

  const externalUsersPanel = () => {
    return <>
      {saasData?.externalUsers?.length > 0 && (
        <Grid item xs={12}>
          <UserConfigTable
            id="external-users-table" // used for automated testing
            heading={
              `External Users (${external.length})`
            }
            shouldHideSaasLogoInHeading={true}
            saas={saas}
            users={external}
            singlePanelUrl={
              singlePanelUrl(
                searchParams,
                riskTypeIdentifiers.externalUsers,
                saasData?.externalUsers?.length
              )
            }
          />
        </Grid>
      )}
    </>;
  }

  const dlpPanel = () => {
    return <>
      {risks?.length > 0  && (
        <Grid item xs={12}>
          <UserActionTable
            id="dlp-table" // used for automated testing
            users={risks}
            heading={
              <DLPHeading
                dlpCount={risks?.length}
                exportNameFragment={DLPExportFilenameFragment(risks)}
              />
            }
            shouldShowSaasColumn={false}
            shouldShowPolicyColumn={true}
            shouldShowSharedToColumn={true}
            shouldShowSharedItemColumn={true}
            singlePanelUrl={
              singlePanelUrl(
                searchParams,
                riskTypeIdentifiers.dlp,
                risks?.length
              )
            }
            dateColumnLabel='Triggered'
          />
        </Grid>
      )}
    </>;
  }

  const externalSharesPanel = () => {
    return <>
      {
        shares?.length > 0 && (
            <Grid item xs={12}>
              <UserActionTable
                id="external-shares-table" // used for automated testing
                users={shares}
                heading = {`External Shares (${shares?.length})`}
                shouldShowSaasColumn={false}
                shouldShowSharedToColumn={true}
                shouldShowSharedItemColumn={true}
                singlePanelUrl={
                  singlePanelUrl(
                      searchParams,
                      riskTypeIdentifiers.externalShares,
                      shares?.length
                  )
                }
                dateColumnLabel='Date&nbsp;Shared'
              />
            </Grid>
          )
      }
    </>;
  }

  const externalForwardsPanel = () => {
    const length = forwards?.length;
    return <>
      {length > 0 && (
        <Grid item xs={12}>
          <UserActionTable
            id="external-forwards-table" // used for automated testing
            users={forwards}
            heading = {`External Mail Forwards (${length})`}
            shouldShowSaasColumn={false}
            shouldShowSharedToColumn={true}
            sharedToColumnLabel="Forwarded To"
            singlePanelUrl={
              singlePanelUrl(
                searchParams,
                riskTypeIdentifiers.externalForwards,
                length,
              )
            }
            dateColumnLabel='Date&nbsp;Forwarded'
          />
        </Grid>
      )}
    </>;
  }

  const inactiveUsersPanel = () => {
    return <>
      {inactive?.length > 0 && (
        <Grid item xs={12}>
          <UserConfigTable
            id="inactive-users-table" // used for automated testing
            heading={
              `Inactive Users (${inactive.length})`
            }
            shouldHideSaasLogoInHeading={true}
            shouldShowActionColumn={true}
            saas={saas}
            users={inactive}
            singlePanelUrl={
              singlePanelUrl(
                searchParams,
                riskTypeIdentifiers.inactive,
                inactive?.length
              )
            }
            DomainFilter={InactiveDomainFilter}
          />
        </Grid>
      )}
    </>;
  }

  function allPanels() {
    return <>
      {allUserPanel()}
      {mfaDisabledPanel()}
      {inactiveUsersPanel()}
      {ghostPanel()}
      {externalUsersPanel()}
      {externalSharesPanel()}
      {externalForwardsPanel()}
      {dlpPanel()}
    </>;
  }

  // Not the easiest code to understand.
  //
  // The shared function riskTypeToDisplay returns a url
  // query-string fragment rom the current url - the fragment
  // with the key "risk".  This function compares that
  // fragment with values in the riskTypeIdentifiers 'enum'
  // to pick the panel to display in full-page mode -
  // or display all panels in compact mode.
  const panelToDisplay = () => {
    let result = {
      isViewingSinglePanel: false,
      panel: allPanels()
    }

    switch(riskTypeToDisplay(searchParams)) {
      case riskTypeIdentifiers.allUsers:
        result = {
          panel: allUserPanel(),
          isViewingSinglePanel: true,
        };
        break;
      case riskTypeIdentifiers.mfaDisabled:
        result = {
          panel: mfaDisabledPanel(),
          isViewingSinglePanel: true,
        };
        break;
      case riskTypeIdentifiers.ghosts:
        result = {
          panel: ghostPanel(),
          isViewingSinglePanel: true,
        };
        break;
      case riskTypeIdentifiers.externalUsers:
        result = {
          panel: externalUsersPanel(),
          isViewingSinglePanel: true,
        };
        break;
      case riskTypeIdentifiers.dlp:
        result = {
          panel: dlpPanel(),
          isViewingSinglePanel: true,
        };
        break;
      case riskTypeIdentifiers.externalShares:
        result = {
          panel: externalSharesPanel(),
          isViewingSinglePanel: true,
        };
        break;
      case riskTypeIdentifiers.externalForwards:
        result = {
          panel: externalForwardsPanel(),
          isViewingSinglePanel: true,
        };
        break;
      case riskTypeIdentifiers.inactive:
        result = {
          panel: inactiveUsersPanel(),
          isViewingSinglePanel: true,
        };
        break;
    }
    return result;
  }

  const {panel, isViewingSinglePanel} = panelToDisplay();

  return (
    <>
      {/*<PageTitle title={`SaaS Dashboard - Detexian - ${saasData?.name}`} />*/}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CountSummaryHeader
            saasNameShouldBeLink={isViewingSinglePanel}
            summaryData={{
              saas,
              connectedSaas,
              HPUs,
              PUs,
              Us,
              external,
              noOfMfa: mfaDisabled?.length, // noOfMfa,
              inactive,
              forwards,
              shares,
              risks,
              ghosts
            }}
          />
        </Grid>
        {panel}
      </Grid>
    </>
  )
};
