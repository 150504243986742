import {Box, Typography as T, useTheme} from '@mui/material';
import {useEffect, useMemo, useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useLocation, useNavigate, useParams} from 'react-router';
import Check from '@mui/icons-material/Check';
import {useSearchParams} from 'react-router-dom';
import {SaasLogo} from 'src/components/SaasLogo';
import {createLinkToken} from 'src/services/SaasService/AddSaas';
import {getAllSaas} from 'src/services/SaasService/Saases';
import {cacheKeys} from 'src/services/shared/serviceConstants';
import {ISaas} from 'src/types/Saas';
import {LoadingScreen} from 'src/components/LoadingScreen';
import {DtxSpacer} from "src/components/common/DtxSpacer";
import {
  useLegacyQueryWithToken,
  useMutationWithAccessToken
} from "src/hooks/TanstackHooks";
import {capitalizeFirstLetter} from "src/utils/string";
import {goToAddSaasErrorPage} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/misc/UserInputDialog";

const connectionIdQueryParamName = 'connection_id';



export const SuccessPage = ({
   isUpdate
 }: {
  isUpdate: boolean,
}) => {
  const theme = useTheme();
  const {
    data: allSaases,
    isLoading,
  } = useLegacyQueryWithToken(
    [cacheKeys.allSaasList],
    getAllSaas
  )
  const {pathname} = useLocation();
  const navigate = useNavigate();

  const [didSendConnectionId, setDidSendConnectionId] = useState(false);
  const {saasIdentifier} = useParams();

  const saas: ISaas = useMemo(() => {
    const saas_: ISaas = allSaases?.find((aSaas: ISaas) => aSaas.identifier === saasIdentifier)

    return saas_;
  },[allSaases, saasIdentifier]);

  // to update the connected status in the Add SaaS menu
  const queryClient = useQueryClient();
  queryClient.invalidateQueries([cacheKeys.connectedSaasList]);

  // check for a connection_id payload - only used by Employment Hero
  const [searchParams] = useSearchParams();
  const connectionId = searchParams.get(connectionIdQueryParamName);
  // console.log('AddSaasSuccessPage', {connectionId});

  const createLinkTokenMutation = useMutationWithAccessToken({
      mutation: createLinkToken,
      mutationCreationArgs: {
        saasIdentifier,
        connectionId
      },
      tanstackOptions: {
        // this mutation has no data (I think) so don't do this check
        // onSuccess: (data: string | URL | undefined) => {
        //   console.log("onSuccess", {data});
        onError: (error: any) => {
          console.log("onError", {error});
          goToAddSaasErrorPage(
            saas,
            `CreateLinkToken failed for ${saas.name}`
          );
          // alert(`CreateLinkToken failed for ${saas?.name} with error ${error}`);
        }
      }
    }
  )

  useEffect(
    () => {
      if (
        connectionId &&
        !didSendConnectionId // prevent multiple, redundant mutations
      ) {
        if (!!createLinkTokenMutation) {
          setDidSendConnectionId(true);
          // console.log('useEffect - mutating createLinkTokenMutation');
          createLinkTokenMutation.mutate();
          navigate(pathname); // has no query string - avoids spurious mutation
        }
        else {
          console.warn ('createLinkTokenMutation not defined')
        }
       }
    }, [
      connectionId,
      createLinkTokenMutation,
      didSendConnectionId,
      navigate,
      pathname
    ]
  )

  const capitalizedSaasIdentifier = capitalizeFirstLetter(saasIdentifier || '')

  // return !saas ? <LoadingScreen /> :
    return (<Box
      p={10}
      width="100%"
      maxWidth="40rem"
      marginLeft="auto"
      marginRight="auto"
      display="flex"
      flexDirection="column"
      alignItems="left"
      justifyContent="center"
    >
      <Box style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1.0rem',
      }}>
        <Check
          sx={{
            color: theme.more.andyGreen,
            fontSize: '2.5rem',
            fontWeight: 'bold',
          }}
        />
        <h3>
          Successfully {isUpdate ? "updated" : "added"} {
            saas ?
              saas.name :
              capitalizedSaasIdentifier
          }
        </h3>
        {saas ? <SaasLogo
          saasIdentifier={saasIdentifier}
          size="m"
        /> : isLoading ? <LoadingScreen /> :
          <>
          <DtxSpacer />
          <T
            sx={{
              color: theme.more.sjsOrange,
              width: '5rem',
            }}
          >
            {capitalizedSaasIdentifier} logo not found
          </T>
          </>
        }
      </Box>
      <DtxSpacer />
      {!isUpdate &&
          <div>Expect a delay of at least an hour while we gather information and produce your {saas ? saas?.name : capitalizedSaasIdentifier} reports.</div>
      }
    </Box>)

}
