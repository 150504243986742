import {useCallback, useState} from "react";
import {useLegacyQueryWithToken} from "../../../../hooks/TanstackHooks";
import {cacheKeys} from "../../../../services/shared/serviceConstants";
import {getCompanyDomains} from "../../../../services/Domains/DomainQueries";
import {useComponentForQueryResult} from "../../../../hooks/UseComponentForQueryResult";
import {DomainsComponent} from "./DomainsComponent";
import {DefaultTableContainer} from "../../../../components/common/DefaultTable";
import {Box} from "@mui/material";

export const DomainPanel = () => {
  const documentTitle = 'Domains - Detexian';
  document.title = documentTitle;

  const [userErrorMessage, setUserErrorMessge] = useState('');

  const queryResult = useLegacyQueryWithToken(
      [cacheKeys.companyDomains],
      getCompanyDomains,
      {},
      {
        onError: () => {
          setUserErrorMessge('error accessing company domains');
        }
      }
  );

  // builds the successful page for the hook below
  const pageLoadFunction = useCallback(
      () =>
          <DomainsComponent
              companyDomains={queryResult.data}
          />
      ,
      [queryResult.data]
  );

  return     <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: '1000px',
                }}
              >
                <DefaultTableContainer
                  shouldUseTableContainer={false}
                >
                  {useComponentForQueryResult({
                      queryResult: queryResult,
                      pageLoadFunction,
                      userErrorMessage,
                      errorMessageDoesIndicateError: true
                    })}
      </DefaultTableContainer>
  </Box>
};
