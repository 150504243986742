import {IDailyDrift, IDailyInactiveUserDrift} from 'src/types/Drift';
import {doAuthenticatedQuery} from "../shared/doQuery";
import {driftTypes} from "./DriftsService";
import {dailyDriftQuery, dailyInactiveUserDriftQuery} from "./DailyDriftQueries";
import {transformInactiveDrifts, transformMFADrifts} from "./DailyDriftTransform";


export const getMFADailyDrifts = async (
  accessToken: any,
): Promise<IDailyDrift[]> => {

  let theQuery = async (client: any) => {
      return await client.request(
        dailyDriftQuery,
        {
          "driftFilter": {"subject": {"_eq" : driftTypes.mfa}}
        },
      );
    };

  try {
    const response = await doAuthenticatedQuery(theQuery, accessToken);
    return transformMFADrifts(response.daily_drift);
  }
  catch(error) {
    throw error;
  }
};

export const getInactiveUserDailyDrifts = async (
  accessToken: any,
): Promise<IDailyInactiveUserDrift[]> => {

  let theQuery = async (client: any) => {
    return await client.request(
      dailyInactiveUserDriftQuery,
      {},
    );
  };

  try {
    const response = await doAuthenticatedQuery(theQuery, accessToken);
    return transformInactiveDrifts(response.inactive_zombie_drift_count);
  }
  catch(error) {
    throw error;
  }
};