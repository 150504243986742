import {gql} from 'graphql-request';
import {
  nullCollapsableArguments, nullCollapsablePrivilegeLevel, nullCollapsableSaasPubId
} from 'src/services/shared/nullCollapsableUtilities';
import {saasUserFragment} from 'src/services/shared/Fragments';
import {
  transformConnectedSaasFlat,
  transformUser
} from 'src/services/shared/sharedTransforms';
import {doAuthenticatedQuery} from "src/services/shared/doQuery";

const getGhostsQuery = gql`
  query getGhosts(
    $sotPubId: uuid,
    $privilegeLevelBoolExp: privilege_level_comparison_exp,
    $saasIdentifierBoolExp: saas_bool_exp,
    $connectionIdBoolExp: client_saas_bool_exp,
    $sotStatus: String_comparison_exp, # ghost_users_bool_exp,
  ) {
    ghost_users(
      where: {
        sot_saas_pub_id: {_eq: $sotPubId},
        latest_saas_user: {
          privilege_level: $privilegeLevelBoolExp,
          saas: $saasIdentifierBoolExp,
          connection: $connectionIdBoolExp
        },
        sot_status: $sotStatus
      },
      order_by:[
        {latest_saas_user: {privilege_level: asc}},
        {sot_last_updated_time: desc_nulls_last}
        # founders 04 Oct 22:
        # sort by sot status and last login. We do the former only.
      ]
    ) {
      latest_saas_user {
        ...User
      }
      sot_status
      sot_last_updated_time
    }
  }
  ${saasUserFragment}
`;

const getGhostsCountQuery = gql`
  query getGhostsCount(
    $sotPubId: uuid,
    $privilegeLevelBoolExp: privilege_level_comparison_exp,
    $saasIdentifierBoolExp: saas_bool_exp,
    $connectionIdBoolExp: client_saas_bool_exp,
    $sotStatus: String_comparison_exp, # ghost_users_bool_exp,
  ) {
    ghost_users_aggregate(
      where: {
        sot_saas_pub_id: {_eq: $sotPubId},
        latest_saas_user: {
          privilege_level: $privilegeLevelBoolExp,
          saas: $saasIdentifierBoolExp,
          connection: $connectionIdBoolExp
        },
        sot_status: $sotStatus
      },
      order_by:[
        {latest_saas_user: {privilege_level: asc}},
        {sot_last_updated_time: desc_nulls_last}
        # founders 04 Oct 22:
        # sort by sot status and last login. We do the former only.
      ]
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getGhosts = async (
  accessToken: any,
  parameters: any,
  signal?: any, // from tanstack for aborting
) => {
  const {
    saasIdentifier,
    connectionId,
    privilegeLevel,
    shouldShowRecordsWithNullSotStatus = true,
    sot,
  } = parameters;


  const theQuery = async (client: any) => {
    const sotPubId = sot?.pubId;
    if (!sotPubId) throw new Error('getGhosts - missing source of truth');

    const optionalArguments = nullCollapsableArguments({
      saasIdentifier,
      privilegeLevel
    })

    const variables = {
      sotPubId,
      privilegeLevelBoolExp:
        nullCollapsablePrivilegeLevel(privilegeLevel),
      "connectionIdBoolExp": nullCollapsableSaasPubId(connectionId),
      saasIdentifierBoolExp: optionalArguments.saasIdentifierBoolExp,
      sotStatus: shouldShowRecordsWithNullSotStatus ?
        {} : // no filtering
        {_is_null: false},
    }

    const result = client.request(
      getGhostsQuery,
      variables,
    );
    return result;
  };

  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery, accessToken, signal
    );
  }
  catch (error) {
    console.error('getGhosts error', {error})
    throw (error)
  }

  const result = response.ghost_users.map((ghost: any) => {
    return {
      ...(transformUser(
        ghost.latest_saas_user,
      )),
      saas: transformConnectedSaasFlat(
        ghost.latest_saas_user.saas,
        ghost.latest_saas_user.connection
      ),
      statusInSot: ghost.sot_status,
      sotLastUpdatedTime: ghost.sot_last_updated_time
    };
  });
  return result;
};


export const getGhostsCount = async (
  accessToken: any,
  parameters: any,
  signal?: any, // from tanstack for aborting
) => {
  const {
    saasIdentifier,
    connectionId,
    privilegeLevel,
    shouldShowRecordsWithNullSotStatus = true,
    sot,
  } = parameters;


  const theQuery = async (client: any) => {
    const sotPubId = sot?.pubId;
    if (!sotPubId) throw new Error('getGhosts - missing source of truth');

    const optionalArguments = nullCollapsableArguments({
      saasIdentifier,
      privilegeLevel
    })

    const variables = {
      sotPubId,
      privilegeLevelBoolExp:
        nullCollapsablePrivilegeLevel(privilegeLevel),
      "connectionIdBoolExp": nullCollapsableSaasPubId(connectionId),
      saasIdentifierBoolExp: optionalArguments.saasIdentifierBoolExp,
      sotStatus: shouldShowRecordsWithNullSotStatus ?
        {} : // no filtering
        {_is_null: false},
    }

    const result = client.request(
      getGhostsCountQuery,
      variables,
    );
    return result;
  };

  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery, accessToken, signal
    );
  }
  catch (error) {
    console.error('getGhostsCount error', {error})
    throw (error)
  }

  return response.ghost_users_aggregate.aggregate.count;
};
