import {useCallback} from 'react';
import {useComponentForQueryResult} from 'src/hooks/UseComponentForQueryResult';
import {DataSharingComponent} from 'src/pages/DataSharingPage/DataSharingComponent';
import {getDataSharing} from 'src/services/DataSharing/DataSharing';
import {getConnectedSaases} from 'src/services/SaasService/Saases';
import {cacheKeys} from 'src/services/shared/serviceConstants';
import {useLegacyQueryWithToken} from "src/hooks/TanstackHooks";

// handles data aquisition and decides whether to
// return the page, loading or error component
export const DataSharingPage = () => {
  const documentTitle = 'Data Sharing - Detexian';
  document.title = documentTitle;

  const {data: connectedSaases} = useLegacyQueryWithToken(
    [cacheKeys.connectedSaasList],
    getConnectedSaases,
  )

  const queryResult = useLegacyQueryWithToken(
    [cacheKeys.dataSharings],
    getDataSharing,
    {},
    {enabled: !!connectedSaases}
  );

  // builds the successful page for the hook below
  const pageLoadFunction = useCallback(
    () => {
      // console.log({connectedSaases})
      return (
        <DataSharingComponent
          dataSharing={queryResult.data}
          connectedSaases={connectedSaases}
        />
      )
    }
    ,
    [connectedSaases, queryResult.data]
  );

  return useComponentForQueryResult({
    queryResult: queryResult,
    pageLoadFunction,
    userErrorMessage: 'error accessing external shares',
  })
};
