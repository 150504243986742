import {Box, CircularProgress, Divider, Typography, useTheme} from '@mui/material';
import React, {ReactNode} from 'react';
import {useNavigate} from 'react-router';
import {DtxSpacer} from 'src/components/common/DtxSpacer';
import {NumberInCircle} from 'src/pages/DashboardPage/NumberInCircle';
import {clickableDivStyles} from 'src/theme';
import {DefaultContainer} from "../../components/common/DefaultContainer";
import {plural} from "src/utils/string";
import {usePortalContext} from "../../hooks/PortalContext";
import {ErrorMessage} from "../../components/common/ErrorMessage/ErrorMessage";

export type TotalPanelProps = {
  title: string;
  totalCount: number;
  appSplitCount?: number;
  description: ReactNode;
  numberInCircle: number;
  viewAllUrl?: string;
  largeMode?: boolean;
  featureEnabled: boolean;
  isLoading: boolean;
  isError: boolean;
}
// calculate the number of Saases involved in the risk type
export const TotalPanel = ({
  title,
  totalCount,
  appSplitCount,
  description,
  numberInCircle,
  viewAllUrl,
  largeMode=false,
  featureEnabled=true,  // This should default to false once all features are implemented
  isLoading=false,      // This should default to true once all features are implemented
  isError=false,
}: TotalPanelProps) => {
  const navigate = useNavigate()
  const theme = useTheme();
  const {featureDialog} = usePortalContext();
  const {spacing, palette} = theme;

  const handleNumberClick = () => {
    if (featureEnabled && viewAllUrl) {
      navigate(viewAllUrl)
    }
    else {
      if (featureDialog?.open) {
        featureDialog?.open();
      }
    }
  }
  function getTotals() {
    // If there is an error then show a nice message
    if (isError) {
      return <ErrorMessage />
    }

    // If the data is still loading we can't show the totals
    // So we'll show a loading indicator instead
    if (isLoading) {
      return <><CircularProgress size={31} /><Typography
        sx={{
          color: theme.palette.text.secondary,
          fontSize: '1rem',
          padding: spacing(3),
        }}
      >Loading... </Typography></>
    }

    // The data is loaded here so we can return the UI showing it
    return <>
      { // If the feature is not enabled, we want to show the link so they can upgrade
        !!viewAllUrl || !featureEnabled ?
        <Typography
          variant="h1"
          sx={{
            ...clickableDivStyles,
            fontSize: largeMode ? '4rem' : '1.4rem',
            textAlign: 'left'
          }}
          onClick={() => handleNumberClick()}
        >
          Total {totalCount}
        </Typography> :
        <Typography
          variant="h1"
          sx={{
            color: theme.palette.text.primary,
            fontSize: largeMode ? '4rem' : '1.4rem',
            textAlign: 'left'
          }}
        >
          Total {totalCount}
        </Typography>
      }

      {(appSplitCount && appSplitCount > 0) && <Box
        sx={{
          display: 'flex',
          fontStyle: 'italic',
          fontSize: '1.5rem',
          paddingTop: spacing(0.5),
        }}
      >
        <Typography
          sx={{
            color: palette.text.secondary,
            fontSize: '1.1rem',
          }}
        >
          across
        </Typography>
        &nbsp;
        <Typography
          sx={{
            color: 'black',
            fontWeight: 'bold',
            fontSize: '1.1rem',
          }}
        >
          {appSplitCount} App{
          plural(appSplitCount)
        }
        </Typography>
      </Box>
      }
    </>;
  }

  return (
    <DefaultContainer
      sx={{
        display: 'flex',
        maxWidth: '460px',
        minWidth: '460px',
        marginBottom: 0,
        flexBasis: '460px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          margin: 'auto',
          height: '100%',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            padding: spacing(),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              gap: '15px',
            }}
          >
            {!largeMode &&
              // Only show the number in the circle when not in largeMode
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <NumberInCircle number={numberInCircle}/>
                </Box>
                <Divider orientation="vertical"

                         sx={{
                           height: '120px',
                           width: '2px',
                         }}
                />
              </>
            }
            {/*The Main Summary: Type, Total, across Apps*/}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <Typography
                sx={{
                  color: featureEnabled ? theme.palette.text.primary : theme.palette.text.secondary,
                  fontWeight: 'bold',
                  fontSize: largeMode ? '2rem' : '1.5rem',
                }}
              >
                {title}
              </Typography>
              <DtxSpacer space={1}/>
              <Box
                sx={{
                  textAlign: 'center'
                }}
              >
              {
                // If the feature is enabled then show the totals, otherwise show the "upgrade" message
                getTotals()
              }
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Divider/>
          <DtxSpacer/>
          <Typography
            sx={{
              paddingLeft: '34px',
              paddingRight: '21px',
              fontSize: '1rem',
              color: featureEnabled ? theme.palette.text.primary : theme.palette.text.secondary,
            }}
          >
            {description}
          </Typography>
        </Box>
      </Box>
    </DefaultContainer>
  );
};
