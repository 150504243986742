import {graphQLClient} from 'src/services/shared/GraphQLService';
import {IDrift} from 'src/types/Drift';
import {driftFromDefault} from 'src/utils/constants';
import {IAppDrift} from "../../types/AppDrift";
import {allAppDriftsQuery, appDriftsForUser, appDriftsQuery, driftsQuery} from "./DriftQueries";
import {transformAppDrifts, transformDrifts} from "./DriftTransform";
import {useLegacyQueryWithToken} from "../../hooks/TanstackHooks";
import {cacheKeyForAppDrifts} from "../shared/serviceConstants";
import {doAuthenticatedQuery} from "../shared/doQuery";


export const driftTypes = {
  privilege: 'PRIVILEGE_CHANGE',
  mfa: 'MFA_CHANGE',
  appStatus: 'APP_STATUS_CHANGE',
}

type getDriftsOptions = {
  driftType: string,
  saasIdentifier?: string,
  driftFrom?: string,
  idToken?: any,
}

export const getDrifts = async (
  accessToken: any,
  parameters: getDriftsOptions,
): Promise<Record<string, IDrift[]>> => {
  // console.log({options: parameters})
  const {
    driftType,
    saasIdentifier,
    driftFrom = driftFromDefault(),
  } = parameters;
  const client = await graphQLClient({accessToken});

  return client
    ?.request(
      driftsQuery,
      saasIdentifier ?
        {
          driftType,
          saasIdentifier: `^${saasIdentifier}$`,
          driftFrom,
        } :
        {
          driftType,
          driftFrom,
        }
    )
    .then(({drift}) => {
        // console.time('getDrifts transform');
        // takes 35ms for 1k records
        // wouldn't drift.map make more sense than reduce?
        const result = drift.reduce(
          (
            acc: Record<string, IDrift[]>,
            singleDrift: any,
          ) => {
            const change =
              transformDrifts(singleDrift);
            return {
              ...acc,
              [change.saas.identifier]: [
                ...(acc[change.saas.identifier] || []),
                change,
              ],
            };
          },
          {} as Record<string, IDrift[]>,
        );
        // console.timeEnd('getDrifts transform');*/
        return result;
      },
    );
};

export const getAppDrifts = async (
  accessToken: any,
  parameters: any,
): Promise<IAppDrift[]> => {
  const {
    driftFrom = driftFromDefault(),
    appId,
    userPubId
  } = parameters;

  let theQuery;

  // If there is an app id we need to perform the query to only get drifts for this app
  // This query does not include a drift_time as the requirement is to show all drifts in this case
  if (appId) {
    theQuery = async (client: any) => {
      return await client.request(
        appDriftsQuery,
        {
          app_pub_id: appId
        }
      );
    };
  }
  // If there is a user id then query to get the app drifts for that user
  else if(userPubId) {
    theQuery = async (client: any) => {
      return await client.request(
        appDriftsForUser,
        {
          user_pub_id: userPubId
        }
      );
    };
  }
  else {
    // There is no appId or userId so we want to run the query to get all drifts
    // This set of drifts is limited to the drift_from period (28 days)
    theQuery = async (client: any) => {
      return await client.request(
        allAppDriftsQuery,
        {
          driftFrom
        }
      );
    };
  }

  let result;

  try {
    const response = await doAuthenticatedQuery(theQuery, accessToken);
    const appDriftResult = transformAppDrifts(response.drift);
    result = appDriftResult;
  }
  catch(error) {
    throw error;
  }
  return result;
};

export const useApplicationDrifts = (appId?:string) => {
     return useLegacyQueryWithToken(
       [cacheKeyForAppDrifts(appId)],
       getAppDrifts,
       {appId: appId},
     );
}