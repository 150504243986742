// external shares
import {gql} from 'graphql-request';
import {saasUserFragment} from 'src/services/shared/Fragments';
import {
  nullCollapsableConnectionId,
  nullCollapsableSaasIdentifier,
  nullCollapsableUserPubId,
} from 'src/services/shared/nullCollapsableUtilities';
import {transformExternalShare} from 'src/services/shared/sharedTransforms';
import {driftFromDefault} from 'src/utils/constants';
import {doAuthenticatedQuery} from "src/services/shared/doQuery";

export const externalShareQuery = gql`
  query ExternalShares(
    $driftFrom: timestamp,
    $saasIdentifierBoolExp: saas_bool_exp,
    $userPubIdBoolExp: generic_user_bool_exp,    
    $connectionIdBoolExp: uuid_comparison_exp,
  ) {
    file_share_view(
      where: {
        created_time: {_gt: $driftFrom},
        _and: [
          {latest_saas_user: {saas: $saasIdentifierBoolExp}},
          {latest_saas_user: {generic_user: $userPubIdBoolExp}},
          {latest_saas_user: {
            connection: {pub_id: $connectionIdBoolExp}
          }},
        ]
        
      },
      order_by: {created_time: desc}
    ) {
      latest_saas_user{
        ...User
      }
      created_time
      shared_item
      file_share_recipients(where: {external: {_eq: true}}) {
        email
      }
    }
  }
  ${saasUserFragment}
`;

export const getExternalShares = async (
  accessToken: string|undefined,
  parameters: any,
) => {
  const {
    driftFrom = driftFromDefault(),
    saasIdentifier,
    connectionId,
    userPubId,
  } = parameters;

  const userPubIdBoolExp = nullCollapsableUserPubId(userPubId);
  const saasIdentifierBoolExp = nullCollapsableSaasIdentifier(
    saasIdentifier
  );
  const connectionIdBoolExp = nullCollapsableConnectionId(
    connectionId
  );

  const variables = {
    driftFrom,
    saasIdentifierBoolExp,
    userPubIdBoolExp,
    connectionIdBoolExp
  }

  console.log('getExternalShares', {variables});

  const externalShareQueryFunction = async (client: any) => {
    return await client.request(
      externalShareQuery, variables,
    );
  };

  let response;
  try {
    response = await doAuthenticatedQuery(
      externalShareQueryFunction,
      accessToken
    );
    // console.log({response});
  }
  catch (error) {
    console.error('getExternalShares error', {error})
    throw (error)
  }

  response = response.file_share_view;

  // ungroup user and transform field names to camel case
  const result = response.map((aUser: any) => {
    aUser = transformExternalShare(aUser);
    return aUser;
  });
  return result;
};
