import React from 'react';
import {riskTypeIdentifiers} from "../../../routes";
import {RiskRowBox} from "./RiskRowBox";
import {useLegacyQueryWithToken} from "../../../hooks/TanstackHooks";
import {cacheKeys} from "../../../services/shared/serviceConstants";
import {usePortalContext} from "../../../hooks/PortalContext";
import {FeatureCountProps} from "../../../types/FeaturePanelProps";
import {getPanelLink} from "../DashboardPanels/panelCommon";
import {getZombiesCount} from "../../../services/shared/Zombies";
import {TotalPanel} from "../TotalPanel";

interface ZombieCountProps extends FeatureCountProps {
  numberInCircle: number;
}
export const ZombieCount = (props: ZombieCountProps) => {

  const {isLoading, data, isError} = useLegacyQueryWithToken(
    [cacheKeys.zombiesCount],
    getZombiesCount,
  );

  const {isTrial} = usePortalContext();

  return (<RiskRowBox>
    <TotalPanel
      totalCount={data}
      title="Zombie Users"
      description={<span><em>Users that have become active again in the last 28 days</em></span>}
      viewAllUrl={getPanelLink(riskTypeIdentifiers.zombies, data, !isTrial && props.isFeatureOn)}
      numberInCircle={props.numberInCircle}
      featureEnabled={props.isFeatureOn}
      isLoading={isLoading}
      isError={isError}
    />
  </RiskRowBox>)
};
