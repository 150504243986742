import {
  createPortalUserMutation,
  portalUserQuery,
  updatePortalUserMutation,
  singlePortalUserQuery,
} from 'src/services/PortalUsers/PortalUserGraphQL';

import {doAuthenticatedQuery} from "src/services/shared/doQuery";
import {IPortalUser} from "../../types/PortalUser";
import {Auth0UsernamePasswordConnection, portalUserRoles} from "../../utils/constants";
import {graphQLClient} from "../shared/GraphQLService";

export const getPortalUser = async (
  accessToken: string,
  parameters: any) : Promise<IPortalUser> => {
  const {external_id} = parameters;
  const theQuery = async (client: any) => {
    const result = client.request(
      singlePortalUserQuery,
      {
        external_id: external_id
      }
    );
    return result;
  }
  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery, accessToken
    );
  } catch (error) {
    console.error('getPortalUser failed')
    throw (error)
  }

  if (!response || !response.cfg_user || response.cfg_user.length !== 1) {
    throw new Error('Could not load user');
  }

  let user = response.cfg_user[0];
    return {
      id: user.pub_id,
      name: user.name,
      email: user.email,
      disabled: user.is_disabled,
      connectionType: user.connection_type,
      clientRoles: user.user_roles.map((cr:any) => {
        return {
          role:cr.role,
          client_id:cr.client?.pub_id
        }})
    }
}

export const getPortalUsers = async ({
   accessToken,
   client_id,
   parameters,
 }: {
  accessToken: string,
  client_id: string,
  parameters: any
}) : Promise<IPortalUser[]> => {
  const {
    role
  } = parameters
  const theQuery = async (client: any) => {
    // The query to get portal users needs to pass the role of the
    // logged in user to Hasura because it could potentially return companies that this
    // user is not part of
    // We only need to do this if it is an admin, as the other roles should use default Hasura permissions
    if (role === portalUserRoles.admin) {
      client.setHeader("x-hasura-role", role);
    }

    const result = client.request(
      portalUserQuery,
      {
        client_id: client_id
      }
    );
    return result;
  }
  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery, accessToken
    );
  } catch (error) {
    console.error('getPortalUsers failed')
    throw (error)
  }

  return response.list_users.map((u: any) => {
    return {
      id: u.id,
      name: u.name,
      email: u.email,
      disabled: u.disabled,
      connectionType: u.connection_type,
      clientRoles: u.client_roles.map((cr:any) => {
        return {
          role:cr.role,
          client_id:cr.client_id
        }})
    }
  });
}

export const updatePortalUser = async (
  parameters: any,
) => {
  const {
    auth0HasuraAccessToken,
    client_id,
    portalUser
  } = parameters;

  if (!portalUser) throw new Error('existing portal user required');

  const client = await graphQLClient({accessToken: auth0HasuraAccessToken});

  let inputVars:{id: string, client_roles: any[], disabled: boolean, name?: string, email?: string} = {
    id: portalUser.id,
    disabled: portalUser.disabled,
    client_roles:portalUser.clientRoles
  };

  // We only want to include the name and email if the connection is "Username-Password-Authentication"
  if (portalUser.connectionType === Auth0UsernamePasswordConnection) {
    inputVars.name = portalUser.name;
    inputVars.email = portalUser.email;
  }

  const variables = {
    client_id: client_id,
    req: inputVars
  };

  const result = await client?.request(
    updatePortalUserMutation,
    variables
  )

  if (result.error) {
    console.error(result.error)
    throw new Error(result.error.message)
  };

  return result;
}

export const createPortalUser = async (
  parameters: any,
) => {
  const {
    auth0HasuraAccessToken,
    client_id,
    portalUser
  } = parameters;

  if (!portalUser) throw new Error('portal user required');

  const client = await graphQLClient({accessToken: auth0HasuraAccessToken});

  const variables = {
    client_id: client_id,
    req: {
      name: portalUser.name,
      email: portalUser.email,
      client_roles:portalUser.clientRoles,
      disabled: portalUser.disabled,
      initiate_password_reset: portalUser.forcePasswordReset,
    }
  };

  const result = await client?.request(
    createPortalUserMutation,
    variables
  )
  return result;
}
