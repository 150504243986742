import {transformUser} from 'src/services/shared/sharedTransforms';
import {
  appSummaryQuery,
  userAppCountQuery,
  usersForAppQuery,
  appsForUserQuery,
  appsForGenericUserQuery, appInfoQuery,
} from './Queries';
import {doAuthenticatedQuery} from "src/services/shared/doQuery";

import {driftFromDefault} from "../../utils/constants";

import {IAppDrift} from "../../types/AppDrift";
import {transformAppDrifts} from "../DriftService/DriftTransform";
import {addedAppDriftsQuery, addedAppDriftsQueryCount} from "../DriftService/DriftQueries";
import {ApplicationSummary} from "./QueryTypes";

export const getAppSummary = async (
  accessToken: string | undefined,
  parameters: any,
) => {
  const theQuery = async (client: any) => {
    return await client.request(
      appSummaryQuery,
    );
  };
  // throw new Error("AppsSummary simulated Failure")

  let response;
  try {
    response = response = await doAuthenticatedQuery(
      theQuery,
      accessToken
    );
  }
  catch (error) {
    console.error('getAppSummary failed', {error})
    throw (error)
  }

  // console.log('getAppSummary', {response});
  let result = response.app_summary;
  return result;
};

export const getUserAppCounts = async (
  accessToken: string | undefined,
  parameters: any,
) => {
  const theQuery = async (client: any) => {
    return await client.request(
      userAppCountQuery,
    );
  };

  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery,
      accessToken
    );
  }
  catch (error) {
    console.error('getUserAppCounts failed', {error})
    throw (error)
  }

  // console.log('getUserAppCounts', {liveResponse});

  const result = response.app

  // console.log('getUserAppCounts', {result});
  return result;
};

export const getUsersForApp = async (
  accessToken: string | undefined,
  parameters: any,
) => {
  const {
    appPubId
  } = parameters;

  const theQuery = async (client: any) => {
    return await client.request(
      usersForAppQuery,
      {
        pub_id: appPubId
      },
    );
  };

  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery,
      accessToken
    );
  }
  catch (error) {
    console.error('getUsersForApp failed', {error})
    throw (error)
  }

  let result = response?.app?.[0];
  // console.log('untransformed result', result);

  let transformedSaasUsers = result.latest_saas_users.map(
    (user: any) => {
      return {
        ...transformUser(user.latest_saas_user),
        createdDateTime: user.created_datetime,
        lastLogin: user.last_login?.login_datetime
      }
    }
  );
  // console.log({transformedSaasUsers});
  result.latest_saas_users = transformedSaasUsers;
  // console.log({result});
  return result;
};

export const getAppsforUser = async (
  accessToken: string | undefined,
  parameters: any,
) => {
  const {
    userId
  } = parameters;
  const theQuery = async (client: any) => {
    return await client.request(
      appsForUserQuery,
      {
        pub_id: userId
      },
    );
  };

  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery,
      accessToken
    );
  }
  catch (error) {
    console.error('getAppsforUser failed', {error})
    throw (error)
  }

  // throw new Error("AppsForUser simulated Failure");

  // console.log({response})

  const userAppsTransformed = response.latest_saas_user?.[0].user_apps.map((userApp: any) => {
    return userApp.app
  })
  const result = {
    user: {
      email: response.latest_saas_user?.[0].email,
      name: response.latest_saas_user?.[0].name,
      pub_id: response.latest_saas_user?.[0].pub_id,
    },
    userApps: userAppsTransformed
  };
  // result.user_apps = undefined;

  return result;
};

export const getAppsforGenericUser = async (
  accessToken: string | undefined,
  parameters: any,
):Promise<{user: any; userApps: ApplicationSummary}> => {
  const {
    userId
  } = parameters;

  const theQuery = async (client: any) => {
    return await client.request(
      appsForGenericUserQuery,
      {
        pub_id: userId
      },
    );
  };

  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery,
      accessToken
    );
  }
  catch (error) {
    console.error('getAppsforGenericUser failed', {error})
    throw (error)
  }
  // console.log({response})

  // concatenate the apps from each latest_saas_user
  const userAppsTransformed = response.latest_saas_user.reduce(
    (acc: [], saas_user: any) => {
      // console.log({acc}, {saas_user});
      return acc.concat(
        // remove the unwanted app levels
        // i.e. convert {app: {a: 1, b: 2, c: 3}}
        // to just {a: 1, b: 2, c: 3}
        saas_user.apps.map(
          (app: any) => {
            const result = app.app;
            // also copy the the user_count structure to where we expect it
            result.user_count=result.latest_saas_users_aggregate;
            result.last_login=app.last_login?.login_datetime;
            return result;
          }
        )
      )
    },[]
  )
  // console.log({userAppsTransformed});
  const result = {
    user: {
      email: response.latest_saas_user?.[0].email,
      name: response.latest_saas_user?.[0].name,
      pub_id: response.latest_saas_user?.[0].pub_id,
    },
    userApps: userAppsTransformed
  };
  // result.user_apps = undefined;

  return result;
};

// Gets the apps that have been added to an org for the first time in the last (x) days
export const getNewAppsInOrg = async (
    accessToken: string | undefined,
    parameters: any,
): Promise<IAppDrift[]> => {
  const theQuery = async (client: any) => {
    return await client.request(
        addedAppDriftsQuery,
        {
          driftFrom: driftFromDefault(),
          addendumFilter: {"_contains": {"is_new_to_org" : true}}  // We only want drifts that are new to org
        },
    );
  };

  let response;
  try {
    response = await doAuthenticatedQuery(
        theQuery,
        accessToken
    );
  }
  catch (error) {
    console.error('getNewAppsInOrg failed', {error})
    throw (error)
  }

  return transformAppDrifts(response.drift);
};

// Gets the count of apps that have been added to an org for the first time in the last (x) days
export const getNewAppsInOrgCount = async (
  accessToken: string | undefined,
  parameters: any,
): Promise<IAppDrift[]> => {
  const theQuery = async (client: any) => {
    return await client.request(
      addedAppDriftsQueryCount,
      {
        driftFrom: driftFromDefault(),
        addendumFilter: {"_contains": {"is_new_to_org" : true}}  // We only want drifts that are new to org
      },
    );
  };

  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery,
      accessToken
    );
  }
  catch (error) {
    console.error('getNewAppsInOrgCount failed', {error})
    throw (error)
  }

  return response.drift_aggregate.aggregate.count;
};

// Gets the apps that a user has added in the last (x) days
export const getNewUserApps = async (
    accessToken: string | undefined,
    parameters: any,
): Promise<IAppDrift[]> => {
  const theQuery = async (client: any) => {
    return await client.request(
        addedAppDriftsQuery,
        {
          driftFrom: driftFromDefault(),
          addendumFilter: {} // We want to get all drifts
        },
    );
  };

  let response;
  try {
    response = await doAuthenticatedQuery(
        theQuery,
        accessToken
    );
  }
  catch (error) {
    console.error('getNewUserApps failed', {error})
    throw (error)
  }

  return transformAppDrifts(response.drift);
};

// Gets the apps that a user has added in the last (x) days
export const getNewUserAppsCount = async (
  accessToken: string | undefined,
  parameters: any,
): Promise<IAppDrift[]> => {
  const theQuery = async (client: any) => {
    return await client.request(
      addedAppDriftsQueryCount,
      {
        driftFrom: driftFromDefault(),
        addendumFilter: {} // We want to get all drifts
      },
    );
  };

  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery,
      accessToken
    );
  }
  catch (error) {
    console.error('getNewUserAppsCount failed', {error})
    throw (error)
  }

  return response.drift_aggregate.aggregate.count;
};


export const getAppInfo = async (
  accessToken: string | undefined,
  parameters: any,
) => {
  const theQuery = async (client: any) => {
    return await client.request(
      appInfoQuery,
      {
        pub_id: parameters.appPubId,
      },
    );
  };

  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery,
      accessToken
    );
  }
  catch (error) {
    console.error('getAppInfo failed', {error})
    throw (error)
  }

  // This shouldn't happen.  But error if there is more that one app returned in the response.
  if (!response.app || response.app.length === 0 || response.app.length > 1) {
    console.error('getAppInfo could not find the app', response.app.length)
    return undefined;
  }

  // If we get to here we have exactly one app, so it's safe to return it
  return response.app[0];
};