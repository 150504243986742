import {useCallback, useState} from 'react';
import {useLocation, useParams} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {useComponentForQueryResult} from 'src/hooks/UseComponentForQueryResult';
import {authenticationRouteFragment} from 'src/routes';
import {driftTypes, getDrifts} from 'src/services/DriftService/DriftsService';
import {cacheKeys} from 'src/services/shared/serviceConstants';
import {wordForChange} from 'src/utils/constants';
import {DriftsComponent} from './DriftsComponent';
import {getConnectedSaases} from "src/services/SaasService/Saases";
import {useLegacyQueryWithToken} from "src/hooks/TanstackHooks";
import {capitalizeFirstLetter} from "src/utils/string";

const tableLabel = (
  driftCount: number,
  driftDescription: any
) => {
  const plural = driftCount !== 1 ? 's' : '';
  return `${driftDescription}${plural}`
}

const valuesForDrift = (
  {
    pathname,
    searchParams,
    saasIdentifier,
  }: {
    pathname: string;
    searchParams: any;
    saasIdentifier?: string;
  }
) => {
  let result;

  let cacheKey = cacheKeys.privilegeDrifts;
  let driftType = driftTypes.privilege;
  let documentTitle = 'Drift - Detexian';
  let tableLabelDescription = `Privileged Access ${capitalizeFirstLetter(wordForChange)}`;

  if (
    pathname.toLowerCase().includes(
      `/${authenticationRouteFragment}/`
    )
  ) {
    cacheKey = cacheKeys.drifts;
    driftType = driftTypes.mfa;
    documentTitle = 'MFA Drift - Detexian';
    tableLabelDescription = `MFA ${capitalizeFirstLetter(wordForChange)}`;
  }

  result = {
    // we avoid 'undefined' in the cacheKey
    cacheKey: `${cacheKey}${saasIdentifier ? saasIdentifier : ''}`,
    driftType,
    documentTitle,
    tableLabelDescription,
  }
  // console.log('valuesForDrift', {result})
  return result;
}

export const DriftsPage = () => {
  const {pathname} = useLocation();
  const {saasIdentifier} = useParams<{ saasIdentifier?: string }>();
  const [searchParams] = useSearchParams();

  const {
    cacheKey,
    driftType,
    documentTitle,
    tableLabelDescription,
  } = valuesForDrift({
    pathname,
    searchParams,
    saasIdentifier,
  })

  const [userErrorMessage, setUserErrorMessge] = useState('');

  const shouldShowSaasColumn = saasIdentifier === undefined;

  document.title = documentTitle;

  const {data: connectedSaases} = useLegacyQueryWithToken(
    [cacheKeys.connectedSaasList],
    getConnectedSaases,
    {
      onError: () => {
        setUserErrorMessge('error accessing connected saases');
      }
    }
  )

  const queryResult = useLegacyQueryWithToken(
    [cacheKey],
    getDrifts,
    {
      saasIdentifier,
      driftType,
    },
    {
      enabled: !!connectedSaases,
      onError: () => {
        setUserErrorMessge('error accessing drifts');
      }
    }
  );

  // builds the successful page for the hook below
  const pageLoadFunction = useCallback(
    () => {
      // depending on whether the url contains a saasIdentifier
      // we return either all the changes (via flatMap) or just
      // the ones for the saasIdentifier.
      let transformedDrifts: any[] = [];

      if(saasIdentifier) {
        transformedDrifts = queryResult.data[saasIdentifier];
      }
      else {
        transformedDrifts = Object
          .values(queryResult.data)
          .flatMap((changes: any) => changes);
      }

      const theTableLabel = tableLabel(
        transformedDrifts?.length,
        tableLabelDescription
      )

      return (
        <DriftsComponent
          drifts={transformedDrifts}
          connectedSaases={connectedSaases}
          shouldShowSaasColumn={shouldShowSaasColumn}
          saas={saasIdentifier}
          tableLabel={theTableLabel}
        />
      );
    },
    [connectedSaases, queryResult.data, saasIdentifier, shouldShowSaasColumn, tableLabelDescription]
  );

  return useComponentForQueryResult({
    queryResult,
    pageLoadFunction,
    userErrorMessage,
    errorMessageDoesIndicateError: true
  });
};
