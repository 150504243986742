import {findNoOfUsersData, transformSaasResponse,} from 'src/services/shared/sharedTransforms';
import {ConnectedSaas, IGraphQLConnectedSaasResponse} from 'src/types/Saas';
import {ISaasExceptions} from 'src/types/SaasExceptions';
import {driftFromDefault, privilegeStrings} from 'src/utils/constants';
import {getAuthenticationExceptionsQuery} from './AuthExceptionsQueries';
import {combineSaasNicknameWithName} from "src/utils/saasUtils";
import {doAuthenticatedQuery} from "src/services/shared/doQuery";

export const stringForNoRatingData = 'no data';

export const findRatingForPrivilegeLevel = (
  privilegeLevels: any[] | undefined,
  levelToFind: string
): string => {
  const level = privilegeLevels?.filter(
    ({privilege_level}) => privilege_level === levelToFind
  );
  // console.log('findRatingForPrivilegeLevel', {level});
  const result = level?.[0]?.no_mfa_rating;
  // console.log('findRatingForPrivilegeLevel', {result});
  return result || stringForNoRatingData;
}

export const connectedSaasFromResponse = (
  response: IGraphQLConnectedSaasResponse
): ConnectedSaas => {
  // console.log({response})
  const saas = transformSaasResponse(response.saas);
  const result = {
    ...response, // make sure we don't forget new fields
    saas: saas,
    ...saas,
    connectionId: response.pub_id,
    calculatedName: combineSaasNicknameWithName(
      response.saas.description,
      response.nick_name
    ),
    nickname: response.nick_name,
  }
  return result;
}

const transformAuthenticationExceptionsResponse = (clientSaas: IGraphQLConnectedSaasResponse // &
  // {
  //   privilege_levels: PrivilegeLevelUserData[];
  //   drifts_aggregate?: any,
  // }
): ISaasExceptions => {
const {
  // privilege_levels,
  // drifts_aggregate,
  pub_id,
  name,
  description,
} = clientSaas
  // console.log({pub_id}, {name}, {saas});
  const result = ({
    // I don't think this is used
    saas: transformSaasResponse({
      name,
      description,
      pub_id
    }),

    connectedSaas: connectedSaasFromResponse(clientSaas),

    // this whole object now looks like a connected
    // saas to SaasLogoAndName

    // connectionId: pub_id,
    // nickname: nick_name,
    // calculatedName: combineSaasNicknameWithName(
    //   saas.description,
    //   nick_name
    // ),
    // identifier: saas.name,
    // name: saas.description,

    exceptions: [
      {
        name: 'Disabled MFA',
        highlyPrivilegedUser: {
          count: findNoOfUsersData(
            clientSaas.privilege_levels,
            privilegeStrings.highlyPrivileged,
            'no_mfa',
          ),
          rating: findRatingForPrivilegeLevel(
            clientSaas.privilege_levels,
            privilegeStrings.highlyPrivileged
          ),
        },
        privilegedUser: {
          count: findNoOfUsersData(
              clientSaas.privilege_levels,
              privilegeStrings.privileged,
              'no_mfa',
            ),
          rating: findRatingForPrivilegeLevel(
            clientSaas.privilege_levels,
            privilegeStrings.privileged
          ),
        },
        enabledUser: {
          count: findNoOfUsersData(
            clientSaas.privilege_levels,
            privilegeStrings.enabled,
            'no_mfa'
          ),
          rating: findRatingForPrivilegeLevel(
            clientSaas.privilege_levels,
            privilegeStrings.enabled
          ),
        },
        driftCount: clientSaas.drifts_aggregate.aggregate.count,
      },
    ],
  })
  // console.log({result});
  return result;
};

export const getAuthenticationExceptions = async (
  accessToken: any
): Promise<any> => {

  const variables =  {
    drift_from: driftFromDefault(),
  }

  console.log('auth exceptions query', {variables});

  const queryFunction = async (client: any) => {
    return await client.request(
      getAuthenticationExceptionsQuery,
      variables,
    );
  };

  let response;
  try {
    response = await doAuthenticatedQuery(queryFunction, accessToken);
    // console.log({response});
  }
  catch (error) {
    console.error('getAuthenticationExceptions error', {error})
    throw (error)
  }

  const result = response.client_saas.map(
    transformAuthenticationExceptionsResponse
  );
  // console.log({result});

  return result;
};

// export const getAuthenticationExceptions = async (
//   idToken: any
// ): Promise<any> => {
//   const client = await graphQLClient({accessToken: idToken});
//
//   return client
//   ?.request(
//     getAuthenticationExceptionsQuery,
//     {
//       drift_from: driftFromDefault(),
//     }
//   )
//   .then(
//     ({client_saas}) => {
//        const result = client_saas.map(transformAuthenticationExceptionsResponse);
//       // console.log({result});
//       return result;
//     }
//   );
// };



/*
// used by Authentication Exceptions page for 'drill down'
export const getMfaDisabled = async (
  idToken: string|undefined,
  parameters: any,
) => {
  const {
    saasIdentifier,
    privilegeLevel,
  } = parameters;
  if (!saasIdentifier) throw new Error('missing saas name');

  const theQuery = async (client: any) => {
    return await client.request(
      mfaDisabledUserQuery,
      {
        saasIdentifier,
        privilegeLevel,
      },
    );
  };

  // get the goods from the response
  let response = await doQuery(theQuery, idToken);
  response = response.latest_saas_user;

  // simple transform of field names
  const result = response.map((aUser: any) => {
    aUser = transformedUser(aUser)
    return aUser;
  })

  return result;
};
*/


// Christian: "we don't have federation data" - so removed these transform bits
// {
//   name: 'Non-federation',
//   highlyPrivilegedUser: findNoOfUsersData(
//     privilege_levels,
//     'Highly Privileged User',
//     'no_federation'
//   ),
//   privilegedUser: findNoOfUsersData(
//     privilege_levels,
//     'Privileged User',
//     'no_federation'
//   ),
//   enabledUser: findNoOfUsersData(privilege_levels, 'User', 'no_federation'),
// },
