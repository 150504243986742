import React, {useCallback, useState} from 'react';
import {useComponentForQueryResult} from 'src/hooks/UseComponentForQueryResult';
import {ActivityLogComponent} from 'src/pages/ActivityLogPage/ActivityLogComponent';
import {getActivityLog} from 'src/services/ActivityLog/ActivityLog';
import {
  defaultFeaturedFilter,
  mfaDisabledFilter,
  offBoardingFilter,
  onBoardingFilter, privilegeChangeFilter, userActivityFilter,
} from 'src/services/ActivityLog/ActivityLogQuery';

import {cacheKeys} from 'src/services/shared/serviceConstants';
import {
  pastMonthWithoutTime,
  pastQuarterWithoutTime,
  pastYearWithoutTime,
  pastCenturyWithoutTime, preferredDateFormat,
} from 'src/utils/dates';
import {FilterPanel} from './FilterPanel';
import {saasFilterDefaultLabel, useSaasFilterMenu} from './SaasFilterMenu';
import {DtxSpacer} from 'src/components/common/DtxSpacer';
import {activityFilterDefaultLabel, activityFilterMenuIds, useActivityFilterMenu} from './ActivityFilterMenu';
import {useLegacyQueryWithToken} from "src/hooks/TanstackHooks";
import {usePortalContext} from "../../hooks/PortalContext";

export const dateSpanKeys = {
  month: 'month',
  quarter: 'quarter',
  year: 'year',
  all: 'all',
}

export const displayNameForDriftRecords = 'Activity';

const startDateFromRadioSelection = (dateSpan: string) => {
  switch (dateSpan) {
    case dateSpanKeys.month:
      return pastMonthWithoutTime
    case dateSpanKeys.quarter:
      return pastQuarterWithoutTime
    case dateSpanKeys.year:
      return pastYearWithoutTime
    case dateSpanKeys.all:
      return pastCenturyWithoutTime
    default:
      return pastMonthWithoutTime
  }
}

// Defining this pseudo enum outside ActivityLogPage,
// along with other tweaks, solves React maximum-depth
// error for Date Span and Include filters.  Additional
// care required for SaaS filter.

// TypeScript enums are evil so
// export const activityLogFilterQueryKeys = {
//   // startDate: 'startDate',
//   startDateRadio: 'startDateRadio',
//   // includeOnboards: 'includeOnboards',
//   // includeOffboards: 'inludeOffboards',
//   // includeEnabledMFAs: 'includeEnabledMFAs',
// }

// used by FilterPanel
export const startDateRadioKey = 'startDateRadio'

// keys for api query filters
const qk = {
  startDateRadio: startDateRadioKey,
  activityFilter: 'activityFilter'
};

export const ActivityLogPanel = (
  {
    userPubId,
    shouldShowFilterPanel = true,
    shouldHideIfEmpty,
    timeFilterDefault,
  }:

  {
    userPubId?: string
    shouldShowFilterPanel?: boolean,
    shouldHideIfEmpty?: boolean,
    timeFilterDefault: string,
  }) => {
  const [querySettings, setQuerySettings] = useState({
    // [qk.includeOnboards]: true,
    // [qk.includeOffboards]: true,
    // [qk.includeEnabledMFAs]: false,

    // Date Span Filter
    [qk.startDateRadio]: timeFilterDefault, // default for radio group
    // [qk.startDate]: pastMonthWithoutTime, // default - calculation below
  });

  const valueForKey = (key: string) => querySettings [
    key as keyof typeof querySettings
  ]

  const {clientInfo} = usePortalContext();

  // couldn't resist some destructuring of the SaasFilterMenu
  // and its accompanying state value - we only need the getter
  const {
    FilterMenu: SaasFilterMenu,
    selectionState: [selectedSaasFilterOption]
  } = useSaasFilterMenu();

  const {
    FilterMenu: ActivityFilterMenu,
    selectionState: [selectedActivityFilterOption]
  } = useActivityFilterMenu(clientInfo.configuration);

  const filter = () => {
    // The default activity filter should be everything except "APP_STATUS_CHANGE"
    let result = defaultFeaturedFilter(clientInfo.configuration.features);

    switch (selectedActivityFilterOption.id) {
      case activityFilterMenuIds.offboard:
        result = offBoardingFilter
        break;
      case activityFilterMenuIds.onboard:
        result = onBoardingFilter;
        break;
      case activityFilterMenuIds.mfaDisabled:
        result = mfaDisabledFilter;
        break;
      case activityFilterMenuIds.privilegeChange:
        result = privilegeChangeFilter;
        break;
      case activityFilterMenuIds.userActivity:
        result = userActivityFilter;
        break;
    }
    // console.log('filter', {result});
    return result;
  };


  // hash for query-cache key
  const queryHash = () =>
    // `${querySettings[qk.includeOnboards]}` +
    // `${querySettings[qk.includeOffboards]}` +
    // `${querySettings[qk.includeEnabledMFAs]}` +
    querySettings[qk.startDateRadio] +
    // querySettings[qk.startDate] +
    // shouldShowIncludeFilters +
    selectedActivityFilterOption.label +
    selectedSaasFilterOption.label +
    userPubId;

  const startDate = startDateFromRadioSelection(valueForKey(
          qk.startDateRadio) as string);

  const [userErrorMessage, setUserErrorMessge] = useState('');

  const queryResult = useLegacyQueryWithToken(
    [cacheKeys.activityLog + queryHash()],
    getActivityLog,
    {
      startDate,
      saasIdentifier: selectedSaasFilterOption?.value,
      userPubId,
      filter: filter(),
    },
    {
      onError: () => {
        setUserErrorMessge('error accessing activity log');
      }
    }
  );

  // shared filter-change handler

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value: any = event.target.value;
    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    }
    setQuerySettings({
      ...querySettings,
      [event.target.name]: value,
    })
  }

  // console.log({queryResult});
  // const drift = queryResult?.data?.drift;

  // builds the successful page for the useComponentForQueryResult hook below
  const pageLoadFunction = useCallback(
    () => {
      // calculate export file name
      const exportFileName = () => {
        const startDateForDisplay = preferredDateFormat(startDate.toString());
        const endDateForDisplay = preferredDateFormat(new Date().toString());
        const dateRange = `${startDateForDisplay} to ${endDateForDisplay}`;

        let saasDisplay = '';
        let selectedSaas = selectedSaasFilterOption?.label;
        if (selectedSaas !== saasFilterDefaultLabel)
          saasDisplay = `for ${
            selectedSaas
          } `;

        let activityDisplay = 'Activity';
        let selectedActivity = selectedActivityFilterOption?.label;
        if (selectedActivity !== activityFilterDefaultLabel)
          activityDisplay = `${selectedActivity
          }`;
        const result = `${activityDisplay} Log ${saasDisplay}${dateRange}`;
        return result;
      }

      // console.log('drift', queryResult?.data?.drift);

      return <>
        {
          (!shouldHideIfEmpty || (queryResult?.data?.drift?.length > 0)) &&
            <ActivityLogComponent
              drift={queryResult?.data?.drift}
              exportFileName={exportFileName()}
            />
        }
      </>
    },
    [queryResult?.data?.drift, selectedActivityFilterOption?.label, selectedSaasFilterOption?.label, shouldHideIfEmpty, startDate],
  );

  return <>
    {
      (!shouldHideIfEmpty || (shouldShowFilterPanel && queryResult?.data?.drift?.length > 0)) && <>
        <FilterPanel
          displayNameForDriftRecords={displayNameForDriftRecords}
          querySettings={querySettings}
          handleFilterChange={handleFilterChange}
          // shouldShowIncludeFilters={shouldShowIncludeFilters}
          SaasFilterMenu={SaasFilterMenu}
          ActivityFilterMenu={ActivityFilterMenu}
        />
        <DtxSpacer />
      </>
    }
    {
      useComponentForQueryResult({
        queryResult,
        pageLoadFunction,
        userErrorMessage,
        errorMessageDoesIndicateError: true
      })
    }
  </>
};

