import {gql} from 'graphql-request';
import {saasFragment} from 'src/services/shared/Fragments';
import {doAuthenticatedQuery} from "src/services/shared/doQuery";
import {driftFragment} from "../DriftService/DriftQueries";
import {driftFromDefault} from "../../utils/constants";
import {IDrift} from "../../types/Drift";
import {transformDrifts} from "../DriftService/DriftTransform";
import {SaasIdentifier} from "../../types/Saas";

const getZombiesQuery = gql`
query Zombies(
    $driftFrom: timestamp
) {
  drift(
      where: {
      _and:[
      {client_saas: {saas: {name: {_neq: "${SaasIdentifier.bamboohr}"}}}},
      {client_saas: {saas: {name: {_neq: "${SaasIdentifier.employment_hero}"}}}},
      {client_saas: {saas: {name: {_neq: "${SaasIdentifier.hibob}"}}}}
      ],
    subject: {_eq: USER_ACTIVITY},
    drift_time: {_gt: $driftFrom},
    new_value: {_eq : "Active"},
    old_value: {_eq : "Inactive"},
    
    _or:[
      {suppressed_until:{_is_null: true}},
      {suppressed_until:{_lt:"now()"}}
    ],
  },
  order_by: {drift_time: desc}
) {
  ...Drift
  }
}
${driftFragment}
${saasFragment}
`;

const getZombiesCountQuery = gql`
query ZombiesCount(
    $driftFrom: timestamp
) {
  drift_aggregate(
      where: {
      _and:[
      {client_saas: {saas: {name: {_neq: "${SaasIdentifier.bamboohr}"}}}},
      {client_saas: {saas: {name: {_neq: "${SaasIdentifier.employment_hero}"}}}},
      {client_saas: {saas: {name: {_neq: "${SaasIdentifier.hibob}"}}}}
      ],
    subject: {_eq: USER_ACTIVITY},
    drift_time: {_gt: $driftFrom},
    new_value: {_eq : "Active"},
    old_value: {_eq : "Inactive"},
    
    _or:[
      {suppressed_until:{_is_null: true}},
      {suppressed_until:{_lt:"now()"}}
    ],
  }
) {
    aggregate{
      count
    }
  }
}
`;


export const getZombies = async (
  accessToken: any,
  parameters: any,
  signal?: any, // from tanstack for aborting
): Promise<IDrift> => {


  const theQuery = async (client: any) => {
    const variables = {
      driftFrom: driftFromDefault()
    }

    const result = client.request(
      getZombiesQuery,
      variables,
    );
    return result;
  };

  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery, accessToken, signal
    );
  }
  catch (error) {
    console.error('getZombies error', {error})
    throw (error)
  }

  const result = response.drift.map((drift: any) => {
    return transformDrifts(drift);
  });
  return result;
};

export const getZombiesCount = async (
  accessToken: any,
  parameters: any,
  signal?: any, // from tanstack for aborting
): Promise<IDrift> => {


  const theQuery = async (client: any) => {
    const variables = {
      driftFrom: driftFromDefault()
    }

    const result = client.request(
      getZombiesCountQuery,
      variables,
    );
    return result;
  };

  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery, accessToken, signal
    );
  }
  catch (error) {
    console.error('getZombiesCount error', {error})
    throw (error)
  }

  return response.drift_aggregate.aggregate.count;
};
