import {graphQLClient} from 'src/services/shared/GraphQLService';
import {
  addConnection,
  createLinkTokenMutation,
  getOAuthConnectionRedirectMutation,
} from 'src/services/SaasService/AddSaasMutations';

//
// Mutations - Add SaaS
//

// mutation for non-OAuth SaaS connections
export const addSaaSConnection = async (
  parameters: any,
) => {
  const {
    auth0HasuraAccessToken,
    client_id,
    saas, // : string | undefined,
    ...connectionParameters // : string | null,
  } = parameters

  if (!saas) throw new Error('saas required');

  const client = await graphQLClient({accessToken: auth0HasuraAccessToken});

  const variables = {
    client_id: client_id,
    req: {
      saas: saas.identifier,
      ...connectionParameters,
    }
  };
  const result = await client?.request(
    addConnection,
    variables
  )

  if (result.error) {
    console.error(result.error)
    throw new Error(result.error.message)
  };

  return result;
}

export const getOAuthConnectionRedirect = async (
  parameters: any,
) => {
  console.log('running getOAuthConnectionRedirect', {parameters});

  const {
    auth0HasuraAccessToken,
    client_id,
    saas,
    tenantId,
    connectionId,
  } = parameters;

  let result;
  try {
    const client = await graphQLClient({accessToken:auth0HasuraAccessToken});

    const variables = {
      client_id,
      saasName: saas.identifier,
      tenantId,
      connectionId, // to keep typescript happy
    };
    result = await client?.request(
      getOAuthConnectionRedirectMutation,
      variables
    )
  }
  catch (error) {
    console.error(error)
    throw error;
  }

  // console.log(JSON.stringify(result, undefined, 2));

  if (result.error) {
    console.error(result.error)
    throw new Error(result.error.message);
  }

  return result.create_auth_url.url;
}

// mutation for Employment Hero second step in the OAuth connection
export const createLinkToken = async (
  parameters: any,
) => {
  const {
    auth0HasuraAccessToken,
    client_id,
    saasIdentifier, // : string | undefined,
    connectionId // : string | null,
  } = parameters
  const client = await graphQLClient({accessToken: auth0HasuraAccessToken});

  const variables = {
    client_id: client_id,
    saas_name: saasIdentifier,
    connection_id: connectionId,
  };

  const result = connectionId && (await client?.request(
    createLinkTokenMutation,
    variables
  ))

  if (result.error) throw new Error(result.error.message);
  if (result === undefined) throw new Error('missing connection_id');

  return result?.create_link_token.url;
}
