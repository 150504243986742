import {useLegacyQueryWithToken} from "../../../hooks/TanstackHooks";
import {cacheKeys} from "../../../services/shared/serviceConstants";
import React from "react";
import {getZombies} from "../../../services/shared/Zombies";
import {DriftGrid} from "../../../components/common/Tables/DriftGrid/DriftGrid";
import {getGridHeadingCount} from "../../../components/common/Tables/Utilities";
import {usePortalContext} from "../../../hooks/PortalContext";

export const ZombiePanel = () => {
  const {clientInfo} = usePortalContext();
  const {isLoading, data} = useLegacyQueryWithToken(
    [cacheKeys.zombies],
    getZombies,
    {},
    {
      // Only perform the query if the feature is turned on
      enabled: clientInfo?.configuration?.features?.activity
    }
  );

  return <>
    {
        <DriftGrid
          rowIdKey={'id'}
          headerTitle={getGridHeadingCount(data, 'Zombie Users')}
          shouldShowSaasColumn={false}
          shouldShowCombinedColumn={false}
          shouldShowSubjectColumn={false}
          shouldShowUserNameColumn={true}
          rows={data}
          driftDescription={<>Previously <strong>Inactive</strong> users, who have become <strong>Active</strong> in the past 28 days</>}
          isLoading={isLoading}
        />
    }
  </>;
}