import {gql} from "graphql-request";

export const dailyDriftQuery = gql`
query DailyDrift($driftFilter: daily_drift_bool_exp) {
  daily_drift(where: $driftFilter, order_by:{day:desc}) {
    count
    day
    new_value
    old_value
    subject
  }
}
`

export const dailyInactiveUserDriftQuery = gql`
query InactiveUserDailyDrift {
  inactive_zombie_drift_count(order_by:{day:desc}) {
    day
    inactive_disabled_count
    new_inactive_count
    new_zombie_count
  }
}
`