import React, {FC, useEffect, useState} from "react";
import {
  Box,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel, IconButton, InputLabel,
  MenuItem,
  Radio,
  RadioGroup, Typography,ToggleButton,ToggleButtonGroup,
  useTheme
} from "@mui/material";

import DeleteIcon from '@mui/icons-material/Delete';

import {IClientRole, IPortalUser} from "../../../../types/PortalUser";
import {ValidatedField} from "../../AddAndUpdateSaas/dialogs/sharedFields/ValidatedField";
import {FormValuesContext} from "../../../../components/Forms/FormValuesContext";
import {DtxSpacer} from "../../../../components/common/DtxSpacer";
import {isValidEmailAddress} from "../../AddAndUpdateSaas/dialogs/sharedFields/validationFunctions";
import {Auth0UsernamePasswordConnection, portalUserRoles} from "../../../../utils/constants";
import Select from "@mui/material/Select";
import {DefaultButton} from "../../../../components/common/DefaultButton";

import {dataGridRowBorder} from "../../../../theme";
import { DialogHeading } from "../../AddAndUpdateSaas/dialogs/misc/styles";
import {LoadingSpinner} from "../../../../components/common/LoadingSpinner";
import {ICompany} from "../../../../types/Company";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

type propTypes = {
  portalUser?: IPortalUser;
  isOpen: boolean;
  isSpinning: boolean;
  setIsOpen: any;
  userRole: string;
  companies: ICompany[];
  onCancel: () => void;
  onConfirm: (userInfo: {
    id?: string
    name: string
    email: string
    clientRoles: IClientRole[],
    disabled: boolean
    forcePasswordReset?: boolean
    connectionType: string
  }) => void;
};

export const EditPortalUserDialog: FC<propTypes> = ({
  isOpen,
  setIsOpen,
  onCancel,
  onConfirm,
  portalUser,
  isSpinning,
  userRole,
  companies,
  ...props
}) => {
  const theme = useTheme();
  const canCreateSuperAdminPubId = process.env.REACT_APP_STAFF_CLIENT_PUB_ID;

  // we pass fieldValuesState and fieldValidationState to children
  const fieldValuesState = useState<{[key: string]: any}>({});
  const [fieldValues, setFieldValues] = fieldValuesState;

  const fieldValidationsState = useState<{[key: string]: any}>({});
  const [fieldValidations, setFieldValidations] = fieldValidationsState;

  const [company, setCompany] = useState('')
  const [clientRoles, setClientRoles] = useState<IClientRole[]>([])
  const [isDisabled, setIsDisabled] = useState(false)
  const [forcePasswordReset, setForcePasswordReset] = useState(true)

  const [
    didFindValidationErrorOnSubmit,
    setDidFindValidationErrorOnSubmit
  ] = useState(false);

  const stateForDialog: any = {
    fieldValuesState,
    fieldValidationsState,
    didFindValidationErrorOnSubmit,
  };

  useEffect(() => {
    if (portalUser) {
      // As the name and email textboxes are wrapped in ValidatedComponents we need to set their
      // default values like this (we also need to set their field validation as true
      // (Assume that they are valid if they have been saved in the first place)
      setFieldValues({name: portalUser.name, email: portalUser.email})
      setFieldValidations({name: true, email: true})
      setIsDisabled(portalUser.disabled);
      setForcePasswordReset(true);
      setClientRoles(portalUser.clientRoles.map((cr) => {return {client_id: cr.client_id, role: cr.role}}));
      setCompany('');
    } else {
      // There is no portalUser (making a new user)
      // So make sure the fields are cleared out in case they were set previously
      setFieldValues({})
      setFieldValidations({})
      setCompany('');
      setIsDisabled(false);
      setForcePasswordReset(true);
      setClientRoles([]);
    }
  },[portalUser, companies, setFieldValues, setFieldValidations]);

  const handleAddCompany = (
    event: any
  ) => {
    let cr = [...clientRoles];
    // Check to make sure this client doesn't already exist in the client roles
    if(cr.filter((c) => c.client_id === event.target.value).length === 0) {
      // It doesn't, so add a new one, default to role to 'user'
      cr.push({client_id: event.target.value, role: portalUserRoles.user});
      setClientRoles(cr);
    }
    // Clear the client dropdown
    setCompany('');
  }

  const handleRoleChange= (
    value: string,
    clientId: string
  ) => {
    if(value === null) return;

    // Find the client role that is being updated
    let cr = [...clientRoles];
    let client = cr.find((c) => c.client_id === clientId);

    // Make sure it is found and then set the new role
    if(client){
      client.role = value;
      setClientRoles(cr);
    }
  }

  const handleClientRoleRemoval = (
    clientId: string
  ) => {
    // Make a new array of all the client roles except with one that's being deleted
    let cr = [...clientRoles.filter((c) => c.client_id !== clientId)];

    // Set the state to trigger rerender
    setClientRoles(cr);
  }

  const handleAccessChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsDisabled(event.target.value === "true")
  }

  const handleForcePasswordChange = (
    event: any
  ) => {
    setForcePasswordReset(event.target.checked)
  }

  const hasValidationError = () => {
    let result;
    const fieldValidationArray = Object.values(fieldValidations);
    if (!fieldValidationArray.length) {
      console.log('hasValidationError - empty fieldValidations');
      return true;
    }
    result = fieldValidationArray.find(
      (valid) => {
        return valid === false;
      })
    result = result !== undefined; // convert to boolean
    return result;
  }

  const handleOkButtonClick = (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (hasValidationError()) {
      setDidFindValidationErrorOnSubmit(true);
    }
    else {
      // There's no validation error so call the onConfirm function
      // and pass the user to it
      onConfirm({
        id: portalUser ? portalUser.id : '',
        name: fieldValues.name,
        email: fieldValues.email,
        disabled: isDisabled,
        forcePasswordReset: forcePasswordReset,
        clientRoles: clientRoles,
        // The connectionType doesn't change, so set it to the existing one.  If there's no existing user default to UsernamePassword
        connectionType: portalUser ? portalUser.connectionType : Auth0UsernamePasswordConnection,
      });
    }
  }

  // Build a list of companies to add to the select
  // Filter out the ones that are already selected as they can't be added twice
  let availableCompanies = companies.filter((c) => !clientRoles.find((cr) => cr.client_id === c.id));
  let companySelectOptions = availableCompanies.map(({id, name}) =>{
    return (<MenuItem key={id} value={id}>{name}</MenuItem>);
  } );

  companySelectOptions.unshift(<MenuItem key={''} value={''} disabled>Add company</MenuItem>)

  let companyRoleList = clientRoles.map((cr) => {
    let clientName = companies.find((c) => c.id === cr.client_id)?.name;
    return <ListItem key={cr.client_id} secondaryAction={
      <IconButton edge="end" aria-label="delete" onClick={() => handleClientRoleRemoval(cr.client_id)}>
        <DeleteIcon />
      </IconButton>
    }>
      <ListItemText id={`company-list-label-${cr.client_id}`} primary={clientName}  />
      <ToggleButtonGroup size="small" exclusive={true} aria-label="Roles" value={cr.role} onChange={(event, value) => handleRoleChange(value, cr.client_id)}>
        { // Only show the super admin role if company is Detexian and the logged in user is an admin
          (userRole === portalUserRoles.admin && canCreateSuperAdminPubId === cr.client_id) &&
            <ToggleButton value={portalUserRoles.admin} key={portalUserRoles.admin} sx={{fontSize: '10px'}} data-testid={`client-role-selector-${cr.client_id}-${portalUserRoles.admin}`}>
              Super admin
            </ToggleButton>
        }
        <ToggleButton value={portalUserRoles.orgAdmin} key={portalUserRoles.orgAdmin} sx={{fontSize: '10px'}}>
          Account Admin
        </ToggleButton>
        <ToggleButton value={portalUserRoles.user} key={portalUserRoles.user} sx={{fontSize: '10px'}}>
          User
        </ToggleButton>
      </ToggleButtonGroup>
    </ListItem>
  });

  return (
    <Dialog
      {...props}
      open={isOpen}
    >
        <Box
          sx={{
            border: dataGridRowBorder,
            borderRadius: theme.more.borderRadius,
            margin: 'auto',
            padding: theme.spacing(2),
            minWidth: '600px',
          }}
        >
          <DialogHeading>
            {portalUser ? 'Update user': 'Add user'}
          </DialogHeading>
          {isSpinning ?
            <LoadingSpinner></LoadingSpinner>:
          <form onSubmit={handleOkButtonClick}>
          <FormValuesContext.Provider
            value={{...stateForDialog}}
          >
            <DtxSpacer space={2} />
            { !portalUser || portalUser.connectionType === Auth0UsernamePasswordConnection
              // Show the name and email if there is either no portalUser (ie. New user)
              // Or there is a portalUser and their connection is "Password"
              ? <>
            <ValidatedField
              placeholder="Name"
              valueKey="name"
              errorLabel="valid name required"
            />
            <DtxSpacer space={2} />
            <ValidatedField
              placeholder="Email"
              valueKey="email"
              errorLabel="valid email required"
              validationFunction={isValidEmailAddress}
            /> </>
              :
              // Otherwise, we are editing an existing user that has a SSO connection
              // So show the read-only fields
            <div>
              <InputLabel id={"update-user-name"}>Name</InputLabel>
              <Typography sx={{fontSize: theme.typography.h5.fontSize}}>{portalUser?.name}</Typography>
              <DtxSpacer space={1} />
              <InputLabel id={"update-user-name"}>Email</InputLabel>
              <Typography sx={{fontSize: theme.typography.h5.fontSize}}>{portalUser?.email}</Typography>
              <DtxSpacer space={1} />
              <Typography sx={{fontSize: theme.typography.fontSize, color: theme.palette.text.secondary}}>The user's name and email are read-only as they are set by an external provider.</Typography>
              <DtxSpacer space={1} />
            </div>
            }
            <DtxSpacer space={2} />
            <InputLabel id="update-user-company">Clients and roles</InputLabel>
            <List
              sx={{ width: '100%'}}
            >
              {companyRoleList }

            </List>
            <Select labelId="update-user-company"
                    value={company}
                    onChange={handleAddCompany}
                    fullWidth={true}
                    displayEmpty={true}>
                      {companySelectOptions}
            </Select>
            <DtxSpacer space={2} />
            <InputLabel id={"update-user-access"}>Access</InputLabel>
            <FormControl>
              <RadioGroup
                name={'disabled'}
                row
                value={isDisabled}
                onChange={handleAccessChange}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Enabled" />
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Disabled" />
              </RadioGroup>
            </FormControl>
            {
              // Only show the "Force password reset" if it is a new user
              !portalUser && <>
            <DtxSpacer space={2} />
            <FormControlLabel
              label="Force password reset"
              control={
                <Checkbox
                  checked={forcePasswordReset}
                  onChange={handleForcePasswordChange}
                />
              }
            />
            </>}
            <DtxSpacer space={2} />
          </FormValuesContext.Provider>
      <DtxSpacer space={2} />
      <Box sx={{textAlign: 'right'}}>
        <DefaultButton
          sx={{marginRight: theme.spacing()}}
          variant="contained"
          onClick={onCancel}
        >
          Cancel
        </DefaultButton>
        <DefaultButton
          sx={{
            marginRight: 0,
          }}
          variant="contained"
          type="submit"
        >
          {
            portalUser ?
              "Update" :
              "Add"
          }
        </DefaultButton>
      </Box>
    </form>
          }
</Box>
</Dialog>)
};
