// "dlp"
import {gql} from 'graphql-request';
import {saasUserFragment} from 'src/services/shared/Fragments';
import {
  nullCollapsableSaasIdentifier,
  nullCollapsableSaasPubId,
  nullCollapsableUserPubId,
} from 'src/services/shared/nullCollapsableUtilities';
import {driftFromDefault} from 'src/utils/constants';
import {doAuthenticatedQuery} from "src/services/shared/doQuery";
import {IRiskPolicyTrigger} from "src/types/SaasUser";
import {transformConnectedSaas, transformUser} from "src/services/shared/sharedTransforms";

export const dlpQuery = gql`
  query DLPQuery(
    $driftFrom: timestamp
    $saasIdentifierBoolExp: saas_bool_exp,
    $userPubIdBoolExp: generic_user_bool_exp,    
    $connectionIdBoolExp: client_saas_bool_exp
  ) {
    dlp_view(
      where: {
        detection_time: {_gt: $driftFrom},
        latest_saas_user: {
          saas: $saasIdentifierBoolExp,
          generic_user: $userPubIdBoolExp,
          connection: $connectionIdBoolExp,
        }
      },
      order_by: {detection_time: desc},
      # doesn't speed things up as much as one might think
      limit: 5000
    ) {
      latest_saas_user {
        ...User
      }
      detection_time
      service
      shared_by
      shared_item
      reason
      dlp_recipients {
        email
      }
    }
  }
  ${saasUserFragment}
`;

/*
        #saas {
        #  ...Saas
        #}
        #connection {
        #  #...Connection
        #  pub_id
        #  nick_name
        #}
        #name
        #email
        #privilege_level
        #generic_user {
        #  pub_id
        #}

  #${saasFragment}
  #${connectionFragment}


 */
// DLP records lacking any field which React and MUI
// Data Grid can use as a unique ID. Christian suggests
// query mods: "get the right pub_id".

// In the meantime, we use this variable as an
// incrementable, temporary, unique id: uniqueId++.
// Smells a bit but works well.

//

let uniqueId = 0;

export const transformDLPEvent = (
  dlpEvent: any
): IRiskPolicyTrigger => {
  const dlp = dlpEvent;
  if (dlp === null || dlp === undefined) {
    console.error("transformPermissionStatus - no dlp event");
    throw new Error('transformDLPEvent = no dlp');
  }
  if (!dlp.dlp_recipients || !dlp.dlp_recipients[0]) {
    // console.warn("transformPermissionStatus - no recipient for dlp event from", dlp.latest_saas_user?.email);
  }
  // console.log('transformDLPEvent', {dlp});
  const result = {
    id: uniqueId++,
    ...transformUser(dlp.latest_saas_user),
    ...transformConnectedSaas(
      dlp.latest_saas_user?.saas,
      dlp.latest_saas_user?.connection
    ),
    service: dlp.service,
    sharedTo: dlp.dlp_recipients, // dlp.dlp_recipients[0]?.email,
    sharedItem: dlp.shared_item,
    policy: dlp.reason,
    date: dlp.detection_time,
  };
  // console.log('transformDLPEvent', {result});
  return result;
};
export const getDLPs = async (
  accessToken: string|undefined,
  parameters: any,
  signal?: any,
) => {
  const {
    driftFrom = driftFromDefault(),
    connectionId,
    saasIdentifier,
    userPubId,
  } = parameters;

  const variables = {
    driftFrom,
    saasIdentifierBoolExp:
      nullCollapsableSaasIdentifier(saasIdentifier),
    connectionIdBoolExp: nullCollapsableSaasPubId(connectionId),
    userPubIdBoolExp: nullCollapsableUserPubId(userPubId),
  }

  console.log('dlp query', {variables});

  const dlpQueryFunction = async (client: any) => {
    return await client.request(
      dlpQuery, variables,
    );
  };

  let response;
  try {
    response = await doAuthenticatedQuery(
      dlpQueryFunction, accessToken, signal
    );
  }
  catch (error) {
    console.error('dlpQuery failed')
    throw (error)
  }

  console.log({response});

  const dlpView = response.dlp_view;

  const result = dlpView.map((aUser: any) => {
    aUser = transformDLPEvent(aUser);
    return aUser;
  });
  return result;
};
