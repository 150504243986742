import {useLegacyQueryWithToken} from "./TanstackHooks";
import {cacheKeys} from "../services/shared/serviceConstants";
import {oneDay} from "../utils/constants";
import {getClientInfoQuery} from "../services/shared/ClientService";

/* This function is to encapsulate calls to getClientInfoQuery
   to ensure that a consistent cacheKey and expiry are used */
export const useClientInfo = (): any => {
    return useLegacyQueryWithToken(
        [cacheKeys.clientInfo],
        getClientInfoQuery,
        {},
        {
            staleTime: oneDay,
            cacheTime: 2 * oneDay,
        });
}