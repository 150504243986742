import {useLegacyQueryWithToken} from "../../../hooks/TanstackHooks";
import {cacheKeys} from "../../../services/shared/serviceConstants";
import {getInactiveUsers} from "../../../services/shared/InactiveUsers";
import {UserConfigTable} from "../../../components/common/Tables/UserConfigTable";
import React from "react";
import {usePortalContext} from "../../../hooks/PortalContext";
import {getGridHeadingCount} from "../../../components/common/Tables/Utilities";

export const InactiveUsersPanel = () => {
  const {clientInfo} = usePortalContext();
  const {isLoading, data} = useLegacyQueryWithToken(
    [cacheKeys.inactiveUsers],
    getInactiveUsers,
    {},
    {
      enabled: clientInfo?.configuration?.features?.activity
    }
  );

  return <>
    {
        <UserConfigTable
          heading={getGridHeadingCount(data, 'Inactive Users')}
          users={data}
          shouldShowActionColumn={true}
          isLoading={isLoading}
        />
    }
  </>;
}