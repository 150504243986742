import {gql} from 'graphql-request';

// Gets a list of companies that the logged in user has access to
export const getAllClientsQuery = gql`
query ListClients {
    client(order_by:{name:asc}){
          pub_id
          name
          plan_type
          slug
          is_disabled
      }
}`;

export const createClientMutation = gql`
mutation CreateClient($client_id: uuid, $client: CreateClientRequest!){
  insert_client_record(client_id: $client_id, client:$client) {
    id
  }
}`;

export const updateClientMutation = gql`
mutation UpdateClient($client_id: uuid, $client: UpdateClientRequest!){
  update_client_record(client_id: $client_id, client:$client) {
    id
  }
}`;

export const deleteClientMutation = gql`
mutation DeleteClient($client_id: uuid!, $req: DeleteClientRequest!){
  delete_client_record(client_id: $client_id, req:$req) {
    id
  }
}`;
