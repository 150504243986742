import React, {useCallback, useState} from 'react';
import {PortalUsersComponent} from "./PortalUsersComponent";
import {useLegacyQueryWithToken, useQueryWithAccessToken} from "../../../../hooks/TanstackHooks";
import {cacheKeys} from "../../../../services/shared/serviceConstants";
import {getPortalUsers} from "../../../../services/PortalUsers/PortalUserQueries";
import {useComponentForQueryResult} from "../../../../hooks/UseComponentForQueryResult";
import {getAllClients} from "../../../../services/Clients/ClientsQueries";
import {DefaultTableContainer} from "../../../../components/common/DefaultTable";
import {Box} from "@mui/material";

export const PortalUsersPanel = ({
                                   userRole,
}:{
  userRole: string,
}) => {
  const documentTitle = 'Users - Detexian';
  document.title = documentTitle;

  const [userErrorMessage, setUserErrorMessge] = useState('');

  const companyQueryResult = useLegacyQueryWithToken(
    [cacheKeys.clients],
    getAllClients,
    {role: userRole},
    {
      onError: () => {
        setUserErrorMessge('error accessing available companies for logged in user');
      }
    }
  );

  const userQueryResult = useQueryWithAccessToken({
      queryKey: [cacheKeys.portalUsers],
      queryFunction: getPortalUsers,
      queryFunctionVariables: {role: userRole},
      tanstackOptions: {
        enabled: !companyQueryResult.isLoading,   // Need to wait for the role to be defined before asking for users
        onError: () => {
          setUserErrorMessge('error accessing portal users');
        }
      }
    }
  );

  // builds the successful page for the hook below
  const pageLoadFunction = useCallback(
    () =>
      <PortalUsersComponent
        userRole={userRole}
        portalUsers={userQueryResult.data}
        companies={companyQueryResult.data}
      />
    ,
    [userQueryResult.data, companyQueryResult.data, userRole]
  );

  return <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minWidth: '1000px',
    }}
  >
    <DefaultTableContainer
      shouldUseTableContainer={false}
    >
      {useComponentForQueryResult({
          queryResult: userQueryResult,
          pageLoadFunction,
          userErrorMessage,
          errorMessageDoesIndicateError: true
        })}
      </DefaultTableContainer>
    </Box>
};
