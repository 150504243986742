import {gql} from 'graphql-request';
import {SaasIdentifier} from "src/types/Saas";

// "counts" of the number of apps that have a particular property
export const appSummaryQuery = gql`
  query AppSummary {
   app_summary(
     where: {
       client_saas: {
         saas: {
           name: {_eq:${SaasIdentifier.microsoft365}}
         }
       }
     }
   ) {      
      lessThan5_count
      fiveTo10_count
      tenTo25_count
      over25_count
      is_enterprise
      calendar_access
      file_access
      write_access
      email_access
      total_count
    }
  }
`;

// Returns all apps in use at a customer site.
// Provides user count and "access" counts for each app.
export const userAppCountQuery = gql`
  query UserAppCount {
     app(
       where: {
         client_saas: {
           saas: {
             name: {_eq:${SaasIdentifier.microsoft365}}
           }
         }
       },
       order_by: {latest_saas_users_aggregate: {count: desc}}
    ) {
      pub_id
      external_id
      application_id
      display_name
      is_enterprise
      is_admin_consented
      user_count: latest_saas_users_aggregate {
        aggregate {
          count
        }
      }
      access {
        write_access
        calendar_access
        file_access
        email_access
      }
    }
  }
`;

// users of a particular app:
export const usersForAppQuery = gql`
  query UsersForApp($pub_id: uuid) {
    app(where: {pub_id: {_eq :$pub_id}}) {
      pub_id
      display_name
      is_enterprise
      latest_saas_users {
        created_datetime
        last_login{
          login_datetime
        }
        latest_saas_user {
          pub_id
          name
          email
          privilege_level
          generic_user {
            pub_id
          }
        }
      }
    }
  }
`;

const appsForUserFragment = gql`
  fragment App on latest_saas_user {
      pub_id
      name
      email
      generic_user {
        pub_id
      }
      apps{
        last_login{
          login_datetime
        }
        app{
          pub_id
          external_id
          application_id
          display_name
          is_enterprise
          is_admin_consented
          latest_saas_users_aggregate {
            aggregate {
              count
            }
          } 
          access {
            write_access
            calendar_access
            file_access
            email_access
          }
        }
      }
  }
`;

export const appInfoQuery = gql`query AppInfo($pub_id: uuid) {
    app(where: {pub_id: {_eq :$pub_id}}) {
      pub_id
      display_name
      is_enterprise
      access {
       write_access
       calendar_access
       file_access
       email_access
      }
    }
   }`;


// TODO combine these two
// apps for a particular user
export const appsForUserQuery = gql`
  query AppsForUser($pub_id: uuid) {
    latest_saas_user(where: {pub_id: {_eq :$pub_id}}){
      ...App
    }
  }
 ${appsForUserFragment}
`;

export const appsForGenericUserQuery = gql`
  query AppsForUser($pub_id: uuid) {
    latest_saas_user(where: {generic_user: {pub_id: {_eq :$pub_id}}}){
      ...App
    }
  }
 ${appsForUserFragment}
`;
