import {Paper, Typography} from '@mui/material';
import {useParams} from 'react-router';
import {cacheKeyForUserData} from 'src/services/shared/serviceConstants';
import {getUserViewDataFromGraphQL} from 'src/services/UserService/UserService';
import {NavigationBarMainViewSelect} from './NavigationBarMainViewSelect';
import {useNavigationBarContainerStyles,} from './styles';
import {UserSearch} from 'src/components/UserSearch';
import {useLegacyQueryWithToken} from "src/hooks/TanstackHooks";
import {usePortalContext} from "../../hooks/PortalContext";
import {planTypes} from "../../utils/constants";

export const UserNavigationBar = () => {
  const {container, mainViewSelect} = useNavigationBarContainerStyles();
  const params = useParams();
  const {clientInfo} = usePortalContext();
  // console.log({params});

  // The fact that params doesn't have a userId key but does have
  // a '*' key indicates a problem in RoutesManagerUser. Specifically
  // it's resolving to pathname = '*' rather than pathname = 'userId'.

  // But it's working for the moment.
  const userPubId = params['*'];

  // TODO slight overkill, redundant with SingleUserPage query.
  // Maybe use a react context?

  const enabled = !!userPubId && userPubId !== '';
  // console.log({userPubId}, {enabled})
  const {
    data: userViewData,
    isLoading: isUserViewLoading,
    error: userViewDataError
  } = useLegacyQueryWithToken(
    [cacheKeyForUserData(userPubId)],
    getUserViewDataFromGraphQL,
    {userId: userPubId},
    {enabled}
  );

  if (userViewDataError) {
    console.error({userViewDataError})
    // return <ErrorScreen
    //   message={'Error accessing user'}
    // />;
    return null;
  }

  // calculate what to show for the current user name
  // either the name, 'loading' or 'Select User'
  const userNameDisplay = enabled ?
    (isUserViewLoading ? 'loading...' :  userViewData?.name)
    :
    'Select User'; // don't show loading if no user

  return (
    <Paper className={container}>
      { // Only show the dropdown menu if the client is unlimited (or trial)
        clientInfo?.planType !== planTypes.FeatureBased && (<>
        <div className={mainViewSelect} data-testid={'NavigationBarMainViewSelect'}>
          <NavigationBarMainViewSelect />
        </div>
        <div style={{
          color: 'lightgray',
          fontSize: '40px',
          fontWeight: 10}}
        >|</div>
        </>)
      }
      <Typography  sx={{marginLeft: '17px'}}>
        {userNameDisplay}
      </Typography>
      <div style={{marginLeft: '17px'}}>
        <UserSearch />
      </div>
    </Paper>
  );
}
