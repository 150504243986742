import {DefaultButton} from "../DefaultButton";
import {Box, useTheme} from "@mui/material";
import React from "react";
import {usePortalContext} from "../../../hooks/PortalContext";

export const FeatureUpgradeButton = () => {
  const theme = useTheme();
  const {spacing} = theme;
  const {featureDialog} = usePortalContext();

  return <Box sx={{textAlign: 'center'}}><DefaultButton
    sx={{
      marginTop: spacing(2),
      marginBottom: spacing(1),
    }}
    variant="contained"
    onClick={() => {
      if(featureDialog?.open) {
        featureDialog?.open();
      }}}>
    Upgrade
  </DefaultButton>
  </Box>
}