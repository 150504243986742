import React from 'react';
import {riskTypeIdentifiers} from "../../../routes";
import {useLegacyQueryWithToken} from "../../../hooks/TanstackHooks";
import {cacheKeys} from "../../../services/shared/serviceConstants";
import {getInactiveUsers, getInactiveUsersCount} from "../../../services/shared/InactiveUsers";
import {usePortalContext} from "../../../hooks/PortalContext";
import {FeatureCountProps} from "../../../types/FeaturePanelProps";
import {getPanelLink, performFeatureBasedQuery} from "../DashboardPanels/panelCommon";
import {DonutDriftChart} from "./DonutDriftChart";
import {DefaultContainer} from "../../../components/common/DefaultContainer";
import {saasCoverageCount} from "../../../utils/saasUtils";
import {getEnabledUsersCount} from "../../../services/shared/EnabledUsers";
import {getInactiveUserDailyDrifts} from "../../../services/DriftService/DailyDriftService";
import {countInactiveDrift} from "../../../components/DriftGraph/DailyDriftDataCounters";
export const InactiveUserChart = (props: FeatureCountProps) => {
  const allUsersQuery = useLegacyQueryWithToken(
    [cacheKeys.allEnabledUsersCount],
    getEnabledUsersCount,
    {},
    {
    }
  );

  const {isLoading: isInactiveLoading, data: inactiveDrifts, isError: isInactiveError} = useLegacyQueryWithToken(
    [cacheKeys.dailyDrifts.inactiveUsers],
    getInactiveUserDailyDrifts,
    {},
    {
    }
  );

  // This query is executed if the feature is turned off - it gets the count of inactive users
  const countQuery = useLegacyQueryWithToken(
    [cacheKeys.inactiveUsersCount],
    getInactiveUsersCount,
    {},
    {
      enabled: !props.isFeatureOn
    }
  );

  // This query is executed if the feature is turned on - it gets the full list of inactive users
  const fullQuery = useLegacyQueryWithToken(
    [cacheKeys.inactiveUsers],
    getInactiveUsers,
    {},
    {
      enabled: props.isFeatureOn
    }
  );

  const {isLoading: isCurrentStateLoading, count, users, isError: isCurrentStateError} = performFeatureBasedQuery(props.isFeatureOn, fullQuery, countQuery);

  // The panel has errored if any of the queries have errored
  let isError = allUsersQuery.isError || isInactiveError || isCurrentStateError;

  // The panel is loading if any of the queries are loading
  let isLoading = allUsersQuery.isLoading || isInactiveLoading || isCurrentStateLoading;

  const {isTrial} = usePortalContext();

  const theSaasCoverageCount = saasCoverageCount(users);

  let inactiveSeries = [{
    name: "Inactive",
    color: "#82ca9d",
    start: count,
    data: inactiveDrifts,
    countFn : countInactiveDrift
  }];

  return (<DefaultContainer
    sx={{
      display: 'flex',
      marginBottom: 0,
      maxWidth: '49%',
      minWidth: '49%',
    }}
  >
    <DonutDriftChart
      title={"Inactive Users"}
      count={count}
      outOf={allUsersQuery.data}
      appCount={theSaasCoverageCount}
      description={<span><em>Users for which Detexian has detected <strong>no activity for 60 days</strong></em></span>}
      driftSeries={inactiveSeries}
      isFeatureOn={props.isFeatureOn}
      isLoading={isLoading}
      isError={isError}
      viewAllUrl={getPanelLink(riskTypeIdentifiers.inactive, count, !isTrial && props.isFeatureOn)}/>
  </DefaultContainer>)
};
