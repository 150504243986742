import {gql} from 'graphql-request';
import {userStatusStrings} from 'src/utils/constants';
import {doAuthenticatedQuery} from "src/services/shared/doQuery";

const enabledUserCountQuery = gql`
  query EnabledUserCount  {
    latest_saas_user_aggregate(
      where: {
        status: {_eq: ${userStatusStrings.enabled}}
      }
    )
    {
      aggregate{
        count
      }
    }
  }
`;

export const getEnabledUsersCount = async (
  accessToken: any,
) => {

  const queryFunction = async (client: any) => {
    return await client.request(
      enabledUserCountQuery,
      {},
    );
  };
  const response = await doAuthenticatedQuery(
    queryFunction,
    accessToken
  );

  const result = response.latest_saas_user_aggregate.aggregate.count;
  return result;

}